import { connect } from 'react-redux';
import { createCategory, updateCategory } from 'shared/store/actions/knowledge.categories.actions';
import { KnowledgeCategoryEditorPopupComponent } from './knowledge-category-editor-popup.component';

const mapStateToProps = ({ knowledge, lang }, { entity }) => ({
  lang: lang.CATEGORY_EDITOR,
});

export const KnowledgeCategoryEditorPopup = connect(mapStateToProps, {
  createCategory,
  updateCategory,
})(KnowledgeCategoryEditorPopupComponent);
