import styles from '../shared.module.scss';
import { useFormik } from 'formik';
import { Button, Divider, TextInput } from 'src/components/design-system';
import { WORKSPACE_SCHEMA_QUICK_LINKS } from '../workspace-editor/workspace.schema';
import { MODES } from '../workspace-general-info';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useRef, useState } from 'react';
import { ImageUploadBox } from '../image-upload-box';

/**
 * Quick link editor component
 * @param {object} props
 * @param {import('../../types').QuickLink} props.quickLink the quick link object
 * @param {(values: import('../../types').QuickLink) => Promise<void>} props.onSubmit function to submit the quick link
 * @param {() => void} props.onCancel function to cancel the quick link
 * @param {'create' | 'edit'} props.mode the mode of the editor
 */
export function QuickLinkEditor({ quickLink, onSubmit, onCancel, mode }) {
  const lang = useSelector(getLang('SETTINGS'));
  const [loading, setLoading] = useState(false);
  const fileUploadRef = useRef(null);

  const applyChanges = async (values, { resetForm }) => {
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
    resetForm();
  };

  const { values, errors, handleSubmit, setFieldValue, isValid, dirty, resetForm } = useFormik({
    initialValues: !quickLink
      ? {
          url: '',
          title: '',
          iconUrl: '',
        }
      : { ...quickLink },
    validationSchema: WORKSPACE_SCHEMA_QUICK_LINKS,
    enableReinitialize: true,
    onSubmit: applyChanges,
  });

  const handleCancel = () => {
    resetForm();
    fileUploadRef?.current?.clearFiles();
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.fields}>
        <TextInput
          label={lang.QUICK_LINK_URL}
          name="url"
          value={values.url}
          error={errors.url}
          onChange={e => setFieldValue('url', e.target.value.trim())}
        />
        <TextInput
          label={lang.QUICK_LINK_TITLE_LABEL}
          name="title"
          value={values.title}
          error={errors.title}
          onChange={e => setFieldValue('title', e.target.value)}
        />

        <ImageUploadBox
          ref={fileUploadRef}
          name="iconUrl"
          value={values.iconUrl}
          onChange={setFieldValue}
          error={errors.iconUrl}
          label={lang.QUICK_LINK_UPDATE_ICON}
          previewLabel={lang.QUICK_LINK_PREVIEW}
        />
      </div>

      <Divider />

      <div className={styles.actions}>
        <Button type="default" onClick={handleCancel}>
          {lang.CANCEL}
        </Button>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          disabled={!isValid || !dirty || loading}
        >
          {mode === MODES.CREATE ? lang.ADD_LINK : lang.APPLY_CHANGES}
        </Button>
      </div>
    </form>
  );
}
