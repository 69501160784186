import { connect } from 'react-redux';
import { createLabCategory, updateLabCategory } from 'shared/store/actions/lab.categories.actions';
import { LabCategoryEditorComponent } from './lab-category-editor.component';

const mapStateToProps = ({ application, lab, lang }, { entity }) => ({
  adminMode: application.adminMode,
  lang: lang.LAB_CATEGORY_EDITOR,
});

const mapDispatchToProps = {
  createCategory: createLabCategory,
  updateCategory: updateLabCategory,
};

export const LabCategoryEditor = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LabCategoryEditorComponent);
