import { connect } from 'react-redux';
import { MovePopupComponent } from '../../../../../../components/move/move-popup.component';
import {
  generateBreadcrumbs,
  getAdmissionCategoriesById,
  getAdmissionCategoryById,
  getAdmissionRoot,
  isSection,
} from 'shared/store/selectors/admission.selector';
import { moveAdmissionCategory } from 'shared/store/actions/admission.actions';
import { STORE_ENTITY_TYPES } from 'shared/constants';

const mapStateToProps = ({ lang, admission }, { id }) => {
  const category = getAdmissionCategoryById(admission, { id });

  return {
    lang: lang.KNOWLEDGE_MOVE,
    item: category,
    getRoot: getAdmissionRoot,
    getCategoriesById: getAdmissionCategoriesById,
    getCategoryById: getAdmissionCategoryById,
    generateBreadcrumbs,
    isSection,
    isCategoryMove: true,
    disabledIds: category.subcategories.concat(category.id),
    parentId: category.path.split(',').pop(),
    entity: STORE_ENTITY_TYPES.ADMISSION,
  };
};

export const AdmissionCategoryMovePopup = connect(mapStateToProps, {
  doMove: moveAdmissionCategory,
})(MovePopupComponent);
