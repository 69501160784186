import { connect } from 'react-redux';
import { MovePopupComponent } from '../../../../../../components/move/move-popup.component';
import {
  generateBreadcrumbs,
  getLabCategoriesById,
  getLabCategoryById,
  getLabRoot,
  isSection,
} from 'shared/store/selectors/lab.selector';
import { moveLabCategory } from 'shared/store/actions/lab.categories.actions';
import { STORE_ENTITY_TYPES } from 'shared/constants';

const mapStateToProps = ({ lang, lab }, { id }) => {
  const category = getLabCategoryById(lab, { id });

  return {
    lang: lang.KNOWLEDGE_MOVE,
    item: category,
    getRoot: getLabRoot,
    getCategoriesById: getLabCategoriesById,
    getCategoryById: getLabCategoryById,
    generateBreadcrumbs,
    isSection,
    isCategoryMove: true,
    disabledIds: category?.subcategories?.concat(category.id) || [],
    parentId: category?.path?.split(',').pop() || '',
    entity: STORE_ENTITY_TYPES.LAB,
  };
};

export const LabCategoryMovePopup = connect(mapStateToProps, {
  doMove: moveLabCategory,
})(MovePopupComponent);
