import { connect } from 'react-redux';
import { MovePopupComponent } from '../../../../../../components/move/move-popup.component';
import { moveChecklistAction } from 'shared/store/actions/admission.actions';
import {
  generateBreadcrumbs,
  getAdmissionCategoriesById,
  getAdmissionCategoryById,
  getAdmissionRoot,
  isSection,
} from 'shared/store/selectors/admission.selector';
import { STORE_ENTITY_TYPES } from 'shared/constants';

const mapStateToProps = ({ lang }, { item, categoryId }) => {
  return {
    lang: lang.KNOWLEDGE_MOVE,
    item,
    categoryId,
    disabledIds: [categoryId],
    getRoot: getAdmissionRoot,
    getCategoriesById: getAdmissionCategoriesById,
    getCategoryById: getAdmissionCategoryById,
    generateBreadcrumbs,
    isSection,
    entity: STORE_ENTITY_TYPES.ADMISSION,
  };
};

export const AdmissionMovePopup = connect(mapStateToProps, {
  doMove: moveChecklistAction,
})(MovePopupComponent);
