import React, { useState, useEffect } from 'react';
import styles from './knowledge-items-widget.module.scss';
import ReactPaginate from 'react-paginate';
import { NoContent } from 'src/components/common';
import { KnowledgeDeletePopup } from 'src/pages/application/knowledge/components/knowledge-row/knowledgeDelete';
import { KnowledgeEditorPopup } from 'src/pages/application/knowledge/components/knowledge-row/knowledgeEditor';
import { KnowledgeMovePopup } from 'src/pages/application/knowledge/components/knowledge-row/knowledgeMove';
import { KnowledgeWrapperComponent } from 'src/pages/application/knowledge/components/knowledge-row';
import { Loader } from 'src/components/core';

const KnowledgeItemsWidgetComponent = React.memo(function KnowledgeItemsWidgetComponent({
  title,
  items,
  itemsPerPage,
  paginated,
  adminMode,
  id,
  knowledgeItem,
  allowEditExpired = false,
}) {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [deleteKnowledgeItem, setDeleteKnowledgeItem] = React.useState(null);
  const [knowledgeEntityEditor, setKnowledgeEntityEditor] = React.useState(knowledgeItem);
  const [knowledgeEntityMove, setKnowledgeEntityMove] = React.useState(null);

  React.useEffect(() => {
    setKnowledgeEntityEditor(knowledgeItem);
  }, [knowledgeItem]);

  // Delete knowledge items
  const deleteKnowledgeAction = React.useCallback(({ id, title }) => {
    // TODO: analytics
    setDeleteKnowledgeItem({ id, title });
  }, []);

  const onFinishDelete = React.useCallback(() => {
    setDeleteKnowledgeItem(null);
  }, []);

  const editOrCreateKnowledge = React.useCallback(({ item = {} }) => {
    setKnowledgeEntityEditor(item);
  }, []);

  // Editor
  const onFinishEditor = React.useCallback(() => {
    setKnowledgeEntityEditor(null);
  }, []);

  // Move knowledge items
  const moveKnowledgeAction = React.useCallback(({ id, title, ownerIds }) => {
    // TODO: analytics
    setKnowledgeEntityMove({ id, title, ownerIds });
  }, []);

  const onFinishMove = React.useCallback(() => {
    setKnowledgeEntityMove(null);
  }, []);

  useEffect(() => {
    if (!items) return;

    if (!paginated) {
      setCurrentItems(items);
      return;
    }

    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [items, paginated, itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const renderContent = () => {
    if (currentItems === null) {
      return <Loader />;
    }

    if (currentItems.length === 0) {
      return <NoContent className={styles.noContent} />;
    }

    return currentItems.map(item => (
      <KnowledgeWrapperComponent
        key={item.id}
        item={item}
        draggable={false}
        adminMode={adminMode}
        onDelete={deleteKnowledgeAction}
        onUpdate={editOrCreateKnowledge}
        onMove={moveKnowledgeAction}
        showSpecialty
        allowEditExpired={allowEditExpired}
      />
    ));
  };

  return (
    <div className={styles.content}>
      <label className={styles.title}>{title}</label>
      <div className={styles.widgetdItems}>
        {renderContent()}
        {paginated && items.length > itemsPerPage && (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            className={styles.pagination}
            activeClassName={styles.active}
            nextClassName={styles.nextArrow}
            previousClassName={styles.previousArrow}
            disabledClassName={styles.disabledArrow}
            renderOnZeroPageCount={null}
          />
        )}
      </div>
      {deleteKnowledgeItem && (
        <KnowledgeDeletePopup entity={deleteKnowledgeItem} onFinish={onFinishDelete} />
      )}

      {knowledgeEntityEditor && (
        <KnowledgeEditorPopup knowledge={knowledgeEntityEditor} onFinish={onFinishEditor} />
      )}
      {knowledgeEntityMove && (
        <KnowledgeMovePopup
          isOpen={!!knowledgeEntityMove}
          categoryId={id}
          item={knowledgeEntityMove}
          onFinish={onFinishMove}
          enableCreateLink
        />
      )}
    </div>
  );
});

KnowledgeItemsWidgetComponent.defaultProps = {
  items: [],
  title: '',
  paginated: false,
  adminMode: false,
  itemsPerPage: 5,
  knowledgeItem: null,
};

export { KnowledgeItemsWidgetComponent };
