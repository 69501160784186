import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import styles from './user-details-form.module.scss';
import { SelectBox, TextInput } from '../design-system/forms';
import { useProfileOptions } from 'shared/hooks/profile.hooks';
import {
  ALLOWED_PERMISSIONS_FOR_CHANGE,
  Lang,
  ROUTES,
  INTERNAL_ROUTING,
  USER_PERMISSIONS,
  ROLES_DOCTOR,
  DIVISION,
  SPECIALTY,
  PROFESSIONS_WITH_ROLES,
  ROLES_WITH_LEVEL_OF_TRAINING,
} from 'shared/constants';
import { camelCaseToWords, sortByAlphabet } from 'shared/utils';
import { Button } from '../design-system/buttons';
import { Tag } from 'antd';
import _ from 'lodash';
import { TagInput, Tooltip } from '../core';
import { LabelsSelector } from '../labels/labels-selector';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getWorkspaces } from 'shared/store/selectors/workspace.selector';
import { isWorkspaceRestrictionsEnabled } from 'shared/store/selectors/features.selector';

const PERMISSIONS_SCHEMA = Yup.object().shape({
  permissions: Yup.string()
    .oneOf(Object.values(ALLOWED_PERMISSIONS_FOR_CHANGE))
    .required('Required!'),
});

const PROFILE_SCHEMA = Yup.object().shape({
  email: Yup.array()
    .of(Yup.string().email('Please insert a valid email'))
    .min(1, 'Add one or multiple emails separated by a comma or a space')
    .required('Required!'),
  firstName: Yup.string().nullable(),
  lastName: Yup.string(),
  phone: Yup.string().nullable(),
  pager: Yup.string().nullable(),
  cisco: Yup.string().nullable(),
  profession: Yup.string(),
  role: Yup.string().when('profession', ([profession], schema) =>
    PROFESSIONS_WITH_ROLES.includes(profession) ? schema.required('Required!') : schema.nullable(),
  ),
  levelOfTraining: Yup.string().when('role', ([role], schema) =>
    ROLES_WITH_LEVEL_OF_TRAINING.includes(role) ? schema.required('Required!') : schema.nullable(),
  ),
  department: Yup.string().nullable(),
  rotation: Yup.string().nullable(),
  division: Yup.array()
    .of(Yup.string().oneOf([...Object.values(DIVISION)]))
    .nullable(),
  location: Yup.array().of(Yup.string()).nullable(),
  specialty: Yup.array()
    .of(Yup.string().oneOf([...Object.values(SPECIALTY)]))
    .nullable(),
  title: Yup.string().nullable(),
  restrictedWorkspaces: Yup.array().of(Yup.string()).nullable(),
});

const permissionsOptions = Object.values(ALLOWED_PERMISSIONS_FOR_CHANGE)?.map(value => ({
  id: value,
  value: camelCaseToWords(value),
}));

const UserDetailsFormComponent = React.memo(function UserDetailsFormComponent({
  user,
  lang = Lang.USERS_MANAGEMENT,
  onDeleteUser,
  createUsers,
  updateUser,
  onFinish,
  openNotification,
  onTransferOwnership,
  canEdit = true,
  isBulkUpdate = false,
  isAccountAdmin,
  manageRestrictedWorkspacesAccess,
}) {
  const isNewUser = !user?.id && !isBulkUpdate;
  const history = useHistory();
  const { professional = {}, ...userData } = user || {};

  const [error, setError] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);

  const updateAndTransferOwnership = useCallback(
    data => {
      // Transfer ownership
      const transferOwnership = async ({ altOwnerId }) => {
        setLoading(true);
        const res = await updateUser({ id: user.id, userData: data, altOwnerId });

        if (res?.error) {
          setError(res?.error);
        } else {
          onFinish();
        }

        setLoading(false);
      };

      const dismiss = () => {
        setLoading(false);
      };

      onTransferOwnership({ user, action: transferOwnership, dismiss });
    },
    [onFinish, onTransferOwnership, updateUser, user],
  );

  const updateProfile = useCallback(
    async data => {
      setLoading(true);
      try {
        let res;
        if (isNewUser) {
          res = await createUsers({ userData: data });
        } else {
          // Check if the users permission have changed to viewer
          if (
            user.permissions !== data.permissions &&
            data.permissions === USER_PERMISSIONS.VIEWER
          ) {
            // Have to transfer ownership
            return updateAndTransferOwnership(data);
          }

          res = await updateUser({ id: user.id, userData: data });
        }

        if (
          data.restrictedWorkspaces.length > 0 ||
          (user.restrictedWorkspaces.length > 0 && data.restrictedWorkspaces.length === 0)
        ) {
          const wsAccessRes = await manageRestrictedWorkspacesAccess({
            userIds: isNewUser ? res.users.map(user => user.id) : data.id,
            workspaceIds: data.restrictedWorkspaces,
          });

          if (wsAccessRes?.error) {
            openNotification({
              message: lang.RESTRICTED_WORKSPACE_ERROR,
              type: 'error',
            });
          }
        }

        if (res?.error) {
          setError(res?.error);
        }
        if (res?.errors) {
          openNotification({
            message:
              res.errors ||
              `These users already exist: ${res.errors?.alreadyExists?.map(email => `\n${email}`)}`,
            type: 'error',
          });
        }
        if (res?.users) {
          openNotification({
            message: `${
              isNewUser ? lang.NOTIFICATION_CREATE_SUCCESS : lang.NOTIFICATION_UPDATE_SUCCESS
            }: ${res.users?.map(user => `\n${user?.email}`)}`,
          });
          setError(null);
          onFinish();
        }
      } catch (error) {
        setError(lang.ERROR);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isNewUser,
      createUsers,
      updateUser,
      updateAndTransferOwnership,
      openNotification,
      lang,
      onFinish,
    ],
  );

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    values,
    errors,
    touched,
    isValid,
  } = useFormik({
    validationSchema: isBulkUpdate ? PROFILE_SCHEMA : PROFILE_SCHEMA.concat(PERMISSIONS_SCHEMA),
    initialValues: isBulkUpdate
      ? {
          id: user?.map(user => user?.id),
          email: user?.map(user => user?.email),
        }
      : {
          ...userData,
          email: user?.email ? [user.email] : [],
          firstName: user?.firstName === user?.email ? undefined : user?.firstName,
          ...professional,
          permissions: user?.permissions ?? USER_PERMISSIONS.VIEWER,
        },
    onSubmit: updateProfile,
  });

  const resetField = useCallback(
    field => {
      const newValues = { values };
      delete newValues.values[field];
      resetForm(newValues);
    },
    [values, resetForm],
  );
  const handleDependentFieldChange = useCallback(
    (field, dependentField, value) => {
      if (!value || value !== values[value]) {
        resetField(dependentField);
      }
      setFieldValue(field, value);
    },
    [setFieldValue, resetField, values],
  );

  const handleDeleteUser = useCallback(() => {
    onDeleteUser(user);
  }, [user, onDeleteUser]);

  const {
    departmentOptions,
    rotationOptions,
    professionOptions,
    roleOptions,
    levelOftrainingOptions,
    divisionOptions,
    specialtyOptions,
  } = useProfileOptions({
    profession: values.profession,
    role: values.role,
    department: values.department,
  });

  useEffect(() => {
    if (values.role === ROLES_DOCTOR.RESIDENT) {
      setFieldValue('division', []);
    } else {
      setFieldValue('rotation', '');
    }
  }, [values.role, setFieldValue]);

  useEffect(() => {
    if (!values.profession || !professional?.profession) {
      return;
    }
    if (values.profession !== professional?.profession) {
      setFieldValue('role', '');
      setFieldValue('levelOfTraining', '');
    }
  }, [values.profession, professional?.profession, setFieldValue]);

  const showMultipleEmailField = isNewUser && _.isArray(values.email);
  const showSimpleEmailField = !isNewUser && !isBulkUpdate && values.email?.length === 1;
  const isBulkAdd = isNewUser && _.isArray(values.email) && values.email?.length > 1;

  const editInUserManagementLinkIfAdmin = message => {
    const [first, second] = message.split(', ');

    return isAccountAdmin ? (
      <span className={styles.link}>
        {first},&nbsp;
        <p
          onClick={() =>
            history.push(
              `${ROUTES.USERS_MANAGEMENT}?${INTERNAL_ROUTING.QUERY_PARAMS.USERS}=${user?.id}`,
            )
          }
        >
          {second}
        </p>
      </span>
    ) : null;
  };

  const isWsRestrictionEnabled = useSelector(isWorkspaceRestrictionsEnabled);
  const workspaces = useSelector(getWorkspaces);

  const restrictedWorkspacesOptions = useMemo(() => {
    return workspaces
      .filter(workspace => workspace.restricted)
      .sort((a, b) => sortByAlphabet(a.name, b.name))
      .map(({ id, name }) => ({ id, value: name }));
  }, [workspaces]);

  const hasRestrictedWorkspaces = isWsRestrictionEnabled && restrictedWorkspacesOptions.length > 0;

  const userAsArray = Array.isArray(user) ? user : [user];
  const isWsAccessUpdateForCollaborators = isNewUser
    ? false
    : userAsArray.some(user => user?.permissions !== USER_PERMISSIONS.VIEWER);

  const filterAllowedValues = ({ options, values }) => {
    if (!values) {
      return [];
    }
    const optionsIds = options.map(({ id }) => id);

    return values.filter(id => optionsIds.includes(id));
  };

  const renderRestrictedWsSelector = () => {
    if (!hasRestrictedWorkspaces) {
      return null;
    }

    const selectorComponent = (
      <SelectBox
        allowClear
        multiple
        label={lang.RESTRICTED_WORKSPACE}
        placeholder={lang.RESTRICTED_WORKSPACE_PLACEHOLDER}
        name="restrictedWorkspaces"
        onChange={setFieldValue}
        options={restrictedWorkspacesOptions}
        value={filterAllowedValues({
          options: restrictedWorkspacesOptions,
          values: values.restrictedWorkspaces,
        })}
        error={errors.restrictedWorkspaces}
        disabled={isWsAccessUpdateForCollaborators || !canEdit}
      />
    );

    return isWsAccessUpdateForCollaborators ? (
      <Tooltip placement={'top'} title={lang.RESTRICTED_WORKSPACE_DISCLAIMER}>
        {selectorComponent}
      </Tooltip>
    ) : (
      selectorComponent
    );
  };

  return (
    <div className={classNames(['new-design-system', styles.form])}>
      <div className={styles.fields}>
        {showMultipleEmailField && (
          <div className={styles.emailWrapper}>
            <label>{lang.USERS_EMAIL}</label>
            <TagInput
              label={lang.USERS_EMAIL}
              placeholder={lang.USERS_EMAIL}
              name="email"
              value={values.email}
              error={errors.email}
              touched={touched.email}
              onChange={setFieldValue}
              wrapperClass={styles.emailInput}
              popupClassName={styles.emailDropdown}
            />
          </div>
        )}
        {showSimpleEmailField && (
          <TextInput
            label={lang.USERS_EMAIL}
            placeholder={lang.USERS_EMAIL}
            name="email[0]"
            value={values.email[0]}
            error={errors.email}
            touched={touched.email}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={true}
          />
        )}
        {(isBulkUpdate || isBulkAdd) && (
          <Tag color="magenta" className={styles.note}>
            {isBulkAdd ? lang.MULTIPLE_USERS_ADD_WARNING : lang.MULTIPLE_USERS_EDIT_WARNING}
          </Tag>
        )}
        <TextInput
          label={lang.FIRST_NAME}
          placeholder={lang.FIRST_NAME}
          name="firstName"
          value={values.firstName}
          error={errors.firstName}
          touched={touched.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isBulkUpdate || isBulkAdd || !canEdit}
        />
        <TextInput
          label={lang.LAST_NAME}
          placeholder={lang.LAST_NAME}
          name="lastName"
          value={values.lastName}
          error={errors.lastName}
          touched={touched.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isBulkUpdate || isBulkAdd || !canEdit}
        />
        <TextInput
          allowClear
          label={lang.PHONE}
          placeholder={lang.PHONE}
          name="phone"
          value={values.phone}
          error={errors.phone}
          touched={touched.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isBulkUpdate || isBulkAdd || !canEdit}
        />
        <TextInput
          allowClear
          label={lang.PAGER}
          placeholder={lang.PAGER}
          name="pager"
          value={values.pager}
          error={errors.pager}
          touched={touched.pager}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isBulkUpdate || isBulkAdd || !canEdit}
        />
        <TextInput
          allowClear
          label={lang.CISCO}
          placeholder={lang.CISCO}
          name="cisco"
          value={values.cisco}
          error={errors.cisco}
          touched={touched.cisco}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isBulkUpdate || isBulkAdd || !canEdit}
        />
        <SelectBox
          label={lang.DEPARTMENT}
          placeholder={lang.DEPARTMENT}
          name="department"
          value={values.department}
          error={errors.department}
          onChange={setFieldValue}
          options={departmentOptions}
          disabled={!departmentOptions || !canEdit}
        />
        <SelectBox
          label={lang.PROFESSION}
          placeholder={lang.PROFESSION}
          name="profession"
          value={values.profession}
          error={errors.profession}
          onChange={(field, value) => handleDependentFieldChange('profession', 'role', value)}
          options={professionOptions}
          disabled={!canEdit}
        />
        <SelectBox
          label={lang.ROLE}
          placeholder={lang.ROLE}
          name="role"
          value={values.role}
          error={errors.role}
          onChange={(field, value) => handleDependentFieldChange('role', 'levelOfTraining', value)}
          options={roleOptions ?? []}
          disabled={!roleOptions || !canEdit}
        />
        <SelectBox
          label={lang.LEVEL_OF_TRAINING}
          placeholder={lang.LEVEL_OF_TRAINING}
          name="levelOfTraining"
          value={values.levelOfTraining}
          error={errors.levelOfTraining}
          onChange={setFieldValue}
          options={levelOftrainingOptions ?? []}
          disabled={!levelOftrainingOptions || !canEdit}
        />
        {values?.role === ROLES_DOCTOR.RESIDENT ? (
          <SelectBox
            allowClear
            key="rotation"
            label={lang.ROTATION}
            placeholder={lang.ROTATION}
            name="rotation"
            value={values.rotation}
            error={errors.rotation}
            onChange={setFieldValue}
            options={rotationOptions}
            disabled={!rotationOptions || !canEdit}
          />
        ) : (
          <SelectBox
            key="division"
            allowClear
            multiple
            label={lang.DIVISION}
            placeholder={lang.DIVISION}
            name="division"
            onChange={setFieldValue}
            options={divisionOptions}
            value={values.division}
            disabled={!divisionOptions || !canEdit}
          />
        )}
        <LabelsSelector
          allowClear
          value={values.location}
          error={errors.location}
          placeholder={lang.LOCATION}
          label={lang.LOCATION}
          name="location"
          onChange={setFieldValue}
          wrapperClass={styles.location}
          disabled={isBulkUpdate || isBulkAdd || !canEdit}
        />
        <SelectBox
          allowClear
          multiple
          label={lang.SPECIALTY}
          placeholder={lang.SPECIALTY}
          name="specialty"
          onChange={setFieldValue}
          options={specialtyOptions}
          value={values.specialty}
          error={errors.specialty}
          disabled={!specialtyOptions || !canEdit}
        />
        <TextInput
          allowClear
          label={lang.TITLE}
          placeholder={lang.TITLE}
          name="title"
          value={values.title}
          error={errors.title}
          touched={touched.title}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!canEdit}
        />
        <SelectBox
          label={lang.PERMISSIONS}
          placeholder={lang.PERMISSIONS}
          name="permissions"
          value={values.permissions}
          error={errors.permissions}
          onChange={setFieldValue}
          options={permissionsOptions}
          disabled={isBulkUpdate || isBulkAdd || !permissionsOptions || !canEdit}
        />
        {renderRestrictedWsSelector()}
      </div>

      {canEdit ? (
        <div className={styles.buttonsContainer}>
          {error && <div className={styles.error}>{error}</div>}
          <Button
            onClick={handleSubmit}
            type="primary"
            size="large"
            disabled={!isValid}
            loading={isLoading}
          >
            {isNewUser && !isBulkUpdate ? lang.ADD_NEW_USER : lang.SAVE}
          </Button>
          {!isNewUser && !isBulkUpdate && (
            <Button onClick={handleDeleteUser} type="danger" size="large" disabled={isLoading}>
              {lang.DELETE}
            </Button>
          )}
        </div>
      ) : (
        <>{editInUserManagementLinkIfAdmin(lang.CANNOT_EDIT_HERE)}</>
      )}
    </div>
  );
});

export { UserDetailsFormComponent };
