import { useFormik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { WORKSPACE_DASHBOARD_BANNER_SCHEMA } from '../workspace-editor/workspace.schema';
import styles from '../shared.module.scss';
import { Button, Divider, TextInput } from 'src/components/design-system';
import { ImageUploadBox } from '../image-upload-box';

/**
 * Dashboard banner editor component
 * @param {object} params
 * @param {import('../../types').DashboardBanner} params.dashboardBanner the dashboard banner object
 * @param {(values: import('../../types').DashboardBanner) => Promise<void>} params.onSubmit function to submit the dashboard banner
 * @param {() => void} params.onCancel function to cancel the dashboard banner
 */
export function DashboardBannerEditor({ dashboardBanner, onSubmit, onCancel }) {
  const lang = useSelector(getLang('SETTINGS'));
  const [loading, setLoading] = useState(false);

  const applyChanges = async (values, { resetForm }) => {
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
    resetForm();
  };

  const { values, errors, handleSubmit, setFieldValue, isValid, dirty, resetForm } = useFormik({
    initialValues: !dashboardBanner
      ? {
          mobileImage: '',
          webImage: '',
          imageLink: '',
        }
      : { ...dashboardBanner },
    validationSchema: WORKSPACE_DASHBOARD_BANNER_SCHEMA,
    enableReinitialize: true,
    onSubmit: applyChanges,
  });

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit} style={{ marginBottom: 0 }}>
      <div className={styles.fields}>
        <TextInput
          label={lang.DASHBOARD_BANNER_IMAGE_LINK}
          name="imageLink"
          value={values.imageLink}
          error={errors.imageLink}
          onChange={e => setFieldValue('imageLink', e.target.value.trim())}
          noMarginBottom
          allowClear
        />

        <ImageUploadBox
          name="webImage"
          error={errors.webImage}
          value={values.webImage}
          onChange={setFieldValue}
          label={lang.DASHBOARD_BANNER_WEB_IMAGE}
          previewLabel={lang.QUICK_LINK_PREVIEW}
          allowClear
        />

        <ImageUploadBox
          name="mobileImage"
          value={values.mobileImage}
          onChange={setFieldValue}
          error={errors.mobileImage}
          label={lang.DASHBOARD_BANNER_MOBILE_IMAGE}
          previewLabel={lang.QUICK_LINK_PREVIEW}
          allowClear
        />
      </div>

      <Divider />

      <div className={styles.actions}>
        <Button type="default" onClick={handleCancel}>
          {lang.CANCEL}
        </Button>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          disabled={!isValid || !dirty || loading}
        >
          {lang.APPLY_CHANGES}
        </Button>
      </div>
    </form>
  );
}
