import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { Button } from 'src/components/design-system';
import styles from './render-binder-selector.module.scss';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { RenderBinderSelector } from './render-binder-selector';
import {
  generateBreadcrumbs,
  getCategoriesById,
  getCategoryById,
  getRoot,
  isSection,
} from 'shared/store/selectors/knowledge.selector';

export function RenderPublishButton({ onSelectBinder, onSubmit, disabled }) {
  const lang = useSelector(getLang('BINDER_SELECTOR'));
  const [openBinderSelector, setOpenBinderSelector] = useState(false);

  const onClose = useCallback(() => {
    setOpenBinderSelector(false);
  }, []);

  return (
    <>
      <Button
        type="primary"
        className={styles.headerActionsButton}
        icon={<FontAwesomeIcon icon={faCheck} />}
        onClick={() => setOpenBinderSelector(true)}
        disabled={disabled}
      >
        {lang.PUBLISH_TO_BINDER}
      </Button>
      <RenderBinderSelector
        title={lang.TITLE}
        subtitle={lang.SUBTITLE}
        submitButtonText={lang.PUBLISH}
        selectTarget={onSelectBinder}
        onSubmit={onSubmit}
        isOpen={openBinderSelector}
        onClose={onClose}
        getRoot={getRoot}
        getCategoriesById={getCategoriesById}
        getCategoryById={getCategoryById}
        generateBreadcrumbs={generateBreadcrumbs}
        isSection={isSection}
      />
    </>
  );
}
