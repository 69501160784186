import { notification } from 'antd';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { Button, Divider } from 'src/components/design-system';
import { useWorkspaceApi } from '../../hooks';
import { useWorkspacesContext } from '../../hooks/use-workspaces-context';
import { useState } from 'react';
import styles from '../shared.module.scss';

export function DeleteWorkspace({ workspace, closeDeleteWorkspaceModal }) {
  const lang = useSelector(getLang('SETTINGS'));
  const [api, contextHolder] = notification.useNotification({
    placement: 'bottomRight',
  });
  const [loading, setLoading] = useState(false);

  const { deleteWorkspace } = useWorkspaceApi();
  const { onDeleteWorkspace } = useWorkspacesContext();

  const handleDelete = async () => {
    setLoading(true);

    const res = await deleteWorkspace(workspace.id);

    if (res.error) {
      api.error({
        message: res.error,
      });
      setLoading(false);
      closeDeleteWorkspaceModal();
      return;
    }

    api.success({
      message: lang.DELETE_WORKSPACE_SUCCESS,
    });

    setLoading(false);
    closeDeleteWorkspaceModal();
    onDeleteWorkspace();
  };

  return (
    <>
      {contextHolder}
      <div>
        <h5>{lang.DELETE_WORKSPACE_CONFIRMATION_DESCRIPTION}</h5>
        <Divider />

        <div className={styles.actions}>
          <Button type="default" onClick={closeDeleteWorkspaceModal} disabled={loading}>
            {lang.CANCEL}
          </Button>
          <Button danger type="primary" onClick={handleDelete} loading={loading}>
            {`${lang.DELETE_WORKSPACE} "${workspace.name}"`}
          </Button>
        </div>
      </div>
    </>
  );
}
