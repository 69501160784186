import {
  USERS_MGMT_FETCH,
  USERS_MGMT_UPDATE_PERMISSIONS,
  USERS_MGMT_INVITE_USERS,
  USERS_MGMT_CREATE_USER,
  USERS_MGMT_UPDATE_USER,
  USERS_MGMT_DELETE_USER,
  USERS_MGMT_FETCH_STATS,
  USERS_MGMT_CREATE_USERS_IN_BULK,
  USERS_MGMT_UPDATE_USERS_IN_BULK,
  USERS_MGMT_ADD_RESOURCE_TO_USER,
  AUTH_ADD_RESOURCE_TO_USER,
  AUTH_REMOVE_RESOURCE_FROM_USER,
  USERS_MGMT_REMOVE_RESOURCE_FROM_USER,
  USERS_MGMT_MANAGE_RESTRICTED_WORKSPACES_ACCESS,
} from '.';
import userServiceApi from '../../api/user.service.api';
import _ from 'lodash';
import { applicationRefreshDate } from './application.actions';
import workspaceServiceApi from '../../api/workspace.service.api';

export const fetchUsers = () => async dispatch => {
  const { data, error } = await userServiceApi.getUsers();

  if (error) {
    console.log('Failed to update user permissions');
    return { error: error.message };
  }

  const { users } = data;

  dispatch({
    type: USERS_MGMT_FETCH,
    payload: { users },
  });
};

export const setPermission =
  ({ id, permissions }) =>
  async dispatch => {
    const { error } = await userServiceApi.setUserPermission({ id, permissions });

    if (error) {
      console.log('Failed to update user permissions');
      return { error: error.message };
    }

    dispatch({
      type: USERS_MGMT_UPDATE_PERMISSIONS,
      payload: { id, permissions },
    });
  };

export const inviteUsers =
  ({ emails }) =>
  async dispatch => {
    const { error, data } = await userServiceApi.inviteUsers({ emails });

    if (error) {
      console.log('Failed to invite users');
      return { error: data.message };
    }

    dispatch({
      type: USERS_MGMT_INVITE_USERS,
      payload: data,
    });
  };

export const createUsers =
  ({ userData }) =>
  async dispatch => {
    //check if there is more than one user to create
    const isMultipleUsers = userData?.email?.length > 1;

    if (isMultipleUsers) {
      return dispatch(createUsersInBulk({ users: userData }));
    }

    const [email] = userData.email;

    const { user = null, error = null } = await dispatch(
      createSingleUser({
        user: {
          ...userData,
          email,
        },
      }),
    );

    return { users: user ? [user] : null, errors: error };
  };

export const createSingleUser =
  ({ user }) =>
  async dispatch => {
    const { error, data } = await userServiceApi.createUser({ user });

    if (error) {
      console.log('Failed to create user');
      return {
        error: data.message.includes('already exists')
          ? { alreadyExists: [user.email] }
          : data.message,
      };
    }

    dispatch({
      type: USERS_MGMT_CREATE_USER,
      payload: data,
    });

    return data;
  };

export const createUsersInBulk =
  ({ users }) =>
  async dispatch => {
    const { error, data } = await userServiceApi.createUserInBulk({ users });

    if (error) {
      console.log('Failed to create users');
      return { error: data.message };
    }

    dispatch({
      type: USERS_MGMT_CREATE_USERS_IN_BULK,
      payload: data,
    });

    return data;
  };

export const updateUser =
  ({ id, userData, altOwnerId }) =>
  async dispatch => {
    //check if there is more than one user to update
    const isMultipleUsers = _.isArray(userData.id) && userData?.id?.length > 1;

    if (isMultipleUsers) {
      const users = userData.id.map((id, i) => ({ ...userData, id, email: userData.email[i] }));
      return dispatch(updateUsersInBulk({ users }));
    }

    const [email] = userData.email;

    const { user = null, error = null } = await dispatch(
      updateSingleUser({
        id,
        user: {
          ...userData,
          email,
        },
        altOwnerId,
      }),
    );

    return { users: [user], errors: error };
  };

export const updateSingleUser =
  ({ id, user, altOwnerId }) =>
  async dispatch => {
    const { data, error } = await userServiceApi.updateUser({ id, user, altOwnerId });

    if (error) {
      console.log('Failed to update user data');
      return { error: data.message };
    }

    dispatch({
      type: USERS_MGMT_UPDATE_USER,
      payload: { id, user: data?.user },
    });

    if (altOwnerId) {
      dispatch(applicationRefreshDate());
    }

    return data;
  };

export const addResourceToUser =
  ({ userId, payload }) =>
  async (dispatch, getState) => {
    const { error, data } = await userServiceApi.addResourceToUser({ userId, payload });
    const state = getState();

    if (error) {
      console.log('Failed to add resource to user');
      return { error: data.message };
    }

    if (userId === state.auth?.user?.id) {
      await dispatch({
        type: AUTH_ADD_RESOURCE_TO_USER,
        payload: { resource: data },
      });
    }

    await dispatch({
      type: USERS_MGMT_ADD_RESOURCE_TO_USER,
      payload: { userId, resource: data },
    });

    return data;
  };

export const removeResourceFromUser =
  ({ userId, resourceId }) =>
  async (dispatch, getState) => {
    const { error, data } = await userServiceApi.removeResourceFromUser({ userId, id: resourceId });
    const state = getState();

    if (error) {
      console.log('Failed to remove resource from user');
      return { error: data.message };
    }

    if (userId === state.auth?.user?.id) {
      await dispatch({
        type: AUTH_REMOVE_RESOURCE_FROM_USER,
        payload: { resourceId },
      });
    }

    await dispatch({
      type: USERS_MGMT_REMOVE_RESOURCE_FROM_USER,
      payload: { userId, resourceId },
    });

    return data;
  };

export const updateUsersInBulk =
  ({ users }) =>
  async dispatch => {
    const res = await Promise.all(
      users.map(({ id, ...userData }) => userServiceApi.updateUser({ id, user: userData })),
    );

    const errors = res.filter(({ error, data }) => error || data?.error);

    const updatedUsers = res.map(({ data }) => data?.user);

    dispatch({
      type: USERS_MGMT_UPDATE_USERS_IN_BULK,
      payload: { users: updatedUsers },
    });

    return { users: updatedUsers, errors: errors.length > 0 ? errors : null };
  };

export const deleteUser =
  ({ id, ownerId }) =>
  async dispatch => {
    const { error, data } = await userServiceApi.deleteUser({ id, ownerId });

    if (error) {
      console.log('Failed to delete user');
      return { error: data.message };
    }

    dispatch({
      type: USERS_MGMT_DELETE_USER,
      payload: { id },
    });

    if (ownerId) {
      dispatch(applicationRefreshDate());
    }
  };

export const fetchUserStats = () => async dispatch => {
  const { data, error } = await userServiceApi.getStats();

  if (error) {
    console.log('Failed to fetch user stats');
    return { error: error.message };
  }

  const { stats } = data;

  dispatch({
    type: USERS_MGMT_FETCH_STATS,
    payload: { stats },
  });
};

export const manageRestrictedWorkspacesAccess =
  ({ userIds: initialUserIds, workspaceIds }) =>
  async dispatch => {
    const userIds = Array.isArray(initialUserIds) ? initialUserIds : [initialUserIds];

    const { error, data } = await workspaceServiceApi.manageRestrictedWorkspacesAccess({
      userIds,
      workspaceIds,
    });

    if (error) {
      console.log('Failed to manage restricted workspaces access');
      return { error: data.message };
    }

    dispatch({
      type: USERS_MGMT_MANAGE_RESTRICTED_WORKSPACES_ACCESS,
    });
  };
