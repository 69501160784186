import { Button, ScreenTitle, List } from 'src/components/design-system';
import styles from './workspace-management.module.scss';
import classNames from 'classnames';
import { AllSettingsButton } from '../../settings.page.component';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faLock } from '@fortawesome/pro-light-svg-icons';
import { WorkspaceEditor } from './components/workspace-editor';
import { WorkspacesProvider, useWorkspacesContext } from './hooks/use-workspaces-context';
import { isRoot } from 'shared/store/selectors/auth.selector';

export function WorkspaceManagement() {
  const lang = useSelector(getLang('SETTINGS'));
  const isAllowedToAdd = useSelector(isRoot);
  const { onChangeWorkspace, workspaces, workspace } = useWorkspacesContext();

  const onItemSelected = useCallback(
    id => {
      onChangeWorkspace(
        workspaces.find(workspace => workspace.id === id),
        { setSearchParams: true },
      );
    },
    [workspaces, onChangeWorkspace],
  );

  const handleAddWorkspace = () => {
    const newWorkspace = {
      id: `new-${Date.now()}`,
      name: '',
      operational: {
        quickLinks: [],
        scheduleUrls: [],
        calendarUrl: '',
      },
    };
    onChangeWorkspace(newWorkspace, { setSearchParams: true });
  };

  if (!workspace || !workspaces) {
    return null;
  }

  return (
    <div className={classNames(styles.root, 'new-design-system')}>
      <AllSettingsButton />

      <div className={styles.listAndContent}>
        <List
          dataSource={workspaces}
          icon={() => <FontAwesomeIcon icon={faLock} />}
          showIcon={item => item.restricted}
          header={<ScreenTitle title={lang.WORKSPACE_TITLE} containerStyle={styles.title} />}
          selected={workspace.id}
          labelTextProperty="name"
          onItemSelected={onItemSelected}
          footer={
            isAllowedToAdd ? (
              <Button
                type="text"
                icon={<FontAwesomeIcon icon={faCirclePlus} />}
                onClick={handleAddWorkspace}
              >
                {lang.ADD_WORKSPACE}
              </Button>
            ) : null
          }
        />

        <div className={styles.workspaceContent}>
          <WorkspaceEditor />
        </div>
      </div>
    </div>
  );
}

export function WorkspaceManagementPage() {
  return (
    <WorkspacesProvider>
      <WorkspaceManagement />
    </WorkspacesProvider>
  );
}
