import React, { useRef, useState } from 'react';
import { Search } from './search';
import styles from './header.module.scss';
import { Switch, Tooltip, notification } from 'antd';
import { ROUTES } from 'shared/constants/routes';
import { Lang, DATA_KEYS, colorPalette } from 'shared/constants';
import { MobileQRCode } from '../../../mobile-qr-code';
import { NavLink } from 'react-router-dom';
import { GiveFeedbackReport } from 'src/components/issue-report/give-feedback-report';
import { useHistory, useLocation } from 'react-router';
import { AskPandaButton } from 'src/components/ai';
import { SwitchOrganizationDropdown } from 'src/pages/application/switchOrganization/switch-organization-dropdown';
import { formatInitials, sortByAlphabet } from 'shared/utils';
import { Dropdown } from 'src/components/design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/pro-thin-svg-icons';
import { Avatar } from 'src/components/avatar/avatar.component';

const { HOME_PAGE } = DATA_KEYS;

const HeaderComponent = React.memo(function HeaderComponent({
  lang,
  logout,
  adminMode,
  setAdminMode,
  isCollaborator,
  isAccountAdmin,
  workspaces,
  selectedWorkspaceId,
  selectWorkspace,
  isAIEnabled,
  isCanEditProfile,
  user,
}) {
  const [showIssueReportPopup, setShowIssueReportPopup] = useState(false);
  const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const history = useHistory();
  const location = useLocation();

  const openIssueReportPopup = () => {
    setShowIssueReportPopup(true);
  };

  const closeIssueReportPopup = () => {
    setShowIssueReportPopup(false);
  };

  const mobileQRCodeRef = useRef(null);

  const initials = formatInitials(user);

  const handleSettingsDropdownOpenChange = open => {
    if (!open) {
      // Close the dialog when not hovering on the dropdown menu
      mobileQRCodeRef.current?.closeDialog();
    }
    setSettingsDropdownOpen(open);
  };

  const renderWorkspaceSelector = () => {
    if (!workspaces?.length || workspaces?.length === 1) return;

    const currentWorkspace = workspaces.find(ws => ws.id === selectedWorkspaceId);
    return (
      <Dropdown
        items={workspaces
          .sort((a, b) => sortByAlphabet(a.name, b.name))
          .map(workspace => ({
            key: workspace?.id,
            label: <p className={styles.workspaceItem}>{workspace?.name}</p>,
          }))}
        onSelect={key => {
          selectWorkspace(key);

          if (location.pathname === ROUTES.KNOWLEDGE_HUB) {
            return;
          }

          history.replace({
            search: '',
            pathname: `/${location.pathname.split('/')[1]}`,
          });
        }}
        activeKey={selectedWorkspaceId}
        showArrowIcon
        bordered
        childrenWrapperStyle={styles.workspaceDropdownWrapper}
      >
        <div className={styles.workspacesDropdown}>
          <p className={styles.selectedWorkspaceName}>{currentWorkspace.name}</p>
        </div>
      </Dropdown>
    );
  };

  return (
    <>
      {contextHolder}
      {showIssueReportPopup && (
        <GiveFeedbackReport close={closeIssueReportPopup} notificationApi={notificationApi} />
      )}
      <div className={styles.root}>
        <div className={styles.dropdowns}>
          <SwitchOrganizationDropdown successMessage={lang.SUCCESSFUL_SWITCH_ORG} />

          {renderWorkspaceSelector()}
        </div>

        <div className={styles.searches}>
          <Search />

          {isAIEnabled && <AskPandaButton />}
        </div>

        <div className={styles.profileAndSettings}>
          {isCanEditProfile && (
            <Tooltip title={lang.PROFILE} placement="left" className={styles.avatarTooltip}>
              <button className={styles.avatar} onClick={() => history.push(ROUTES.USER_PROFILE)}>
                <Avatar
                  alt={initials}
                  size="small"
                  bgColor={colorPalette.neutral5}
                  color={colorPalette.primaryMain9}
                  withHover
                  hoverBgColor={colorPalette.neutral1}
                  hoverShadowColor={colorPalette.neutral5}
                />
              </button>
            </Tooltip>
          )}

          <Dropdown
            items={[
              {
                key: HOME_PAGE.SETTINGS,
                label: <NavLink to={ROUTES.SETTINGS}>{lang.SETTINGS}</NavLink>,
                hide: !isAccountAdmin,
              },
              {
                key: HOME_PAGE.USERS_MANAGEMENT,
                label: <NavLink to={ROUTES.USERS_MANAGEMENT}>{lang.USERS_MANAGEMENT}</NavLink>,
                hide: !isAccountAdmin,
              },
              {
                key: HOME_PAGE.ADMIN_MODE,
                label: (
                  <div className={styles.adminMode}>
                    {lang.ADMIN_MODE_TOGGLER}
                    <Switch
                      size="small"
                      checked={adminMode}
                      onChange={setAdminMode}
                      className={styles.toggleAdmin}
                    />
                  </div>
                ),
                hide: !isCollaborator,
                onClick: e => {
                  e.domEvent.stopPropagation();
                  e.domEvent.preventDefault();
                  setAdminMode(!adminMode);
                },
              },
              {
                key: HOME_PAGE.GIVE_FEEDBACK,
                label: lang.GIVE_FEEDBACK,
                onClick: openIssueReportPopup,
              },
              {
                key: HOME_PAGE.TUTORIALS,
                label: (
                  <a href="https://help.c8health.com/" target="_blank" rel="noreferrer">
                    {lang.TUTORIALS}
                  </a>
                ),
                hide: !isCollaborator,
              },
              {
                key: HOME_PAGE.LOGOUT,
                label: lang.LOGOUT,
                onClick: logout,
              },
              {
                key: HOME_PAGE.MOBILE_QR_CODE,
                label: <MobileQRCode ref={mobileQRCodeRef} />,
                onClick: e => {
                  e.domEvent.stopPropagation();
                  e.domEvent.preventDefault();
                },
                style: {
                  padding: 0,
                  margin: 0,
                  width: '100%',
                },
              },
            ]}
            open={settingsDropdownOpen}
            onOpenChange={handleSettingsDropdownOpenChange}
            menu={{ style: { margin: '0 0.25rem' } }}
          >
            <div className={styles.settingsWrapper}>
              <FontAwesomeIcon icon={faGear} size="2x" color={colorPalette.neutral1} />
            </div>
          </Dropdown>
        </div>
      </div>
    </>
  );
});

HeaderComponent.defaultProps = {
  organization: {},
  logout: () => {},
  user: {},
  adminMode: false,
  isCollaborator: false,
  isAccountAdmin: false,
  isSwitchOrganizationAvailable: false,
  setAdminMode: () => {},
  lang: Lang.HEADER,
  feedbackForm: 'mailto:contact@c8health.com',
};

export { HeaderComponent };
