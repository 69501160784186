import React, { useCallback, useEffect } from 'react';
import { RequiredIndication, SelectBox, TextInput } from '../../components/design-system/forms';
import styles from './user-profile.module.scss';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useProfileOptions, useProfileLang } from 'shared/hooks/profile.hooks';
import { DateTime } from 'luxon';
import { Col, notification, Row } from 'antd';
import ReactMarkdown from 'react-markdown';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { LabelsSelector } from '../labels/labels-selector';
import { ProfileLayoutComponent } from '../profiles/profile-layout.component';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { Button, Divider, Notification, ScreenTitle } from '../design-system';
import { Loader } from '../core';
import {
  PROFESSIONS_WITH_ROLES,
  ROLES_DOCTOR,
  ROLES_WITH_LEVEL_OF_TRAINING,
} from 'shared/constants';
import { isAccountAdmin } from 'shared/store/selectors/auth.selector';
import { ProfessionalProfileManager } from '../profiles/professional-profile-manager';

const PROFILE_TYPES = {
  USER: 'USER',
  CONTACT: 'CONTACT',
  SELF: 'SELF',
};

const UserProfileComponent = ({
  user,
  selectedLang,
  isPopup = false,
  onClose = () => {},
  showTermsAndConditions = false,
  type = PROFILE_TYPES.SELF,
  schema: initialSchema = {},
  onUpdate = () => {},
  analyticsTriggers = {},
}) => {
  const [loading, setLoading] = React.useState(false);
  const [api, contextHolder] = notification.useNotification();
  const isAdmin = useSelector(isAccountAdmin);
  const canEdit = type === PROFILE_TYPES.SELF || (type !== PROFILE_TYPES.CONTACT && isAdmin);
  const { professional = {}, profession, ...userData } = user;
  const enforceTNC = isPopup && showTermsAndConditions;
  const history = useHistory();
  const lang = useSelector(getLang('USER_PROFILE'));

  const schema = React.useMemo(
    () =>
      enforceTNC
        ? initialSchema.concat(
            Yup.object().shape({
              approveTermsAndConditions: Yup.boolean().oneOf([true]).required('Required!'),
            }),
          )
        : initialSchema,
    [enforceTNC, initialSchema],
  );

  useEffect(() => {
    analyticsTriggers?.openProfile?.({
      id: user.id.toString(),
      email: user.email,
    });
  }, [user, analyticsTriggers]);

  const openNotification = useCallback(() => {
    api.success({
      message: lang.NOTIFICATION_SUCCESS,
      placement: 'bottomRight',
    });
  }, [api, lang]);

  const onFinish = useCallback(() => {
    if (isPopup || type === PROFILE_TYPES.SELF) {
      onClose();
      return;
    }
    history.goBack();
  }, [isPopup, onClose, history, type]);

  const updateProfile = useCallback(
    async data => {
      setLoading(true);
      const res = await onUpdate({ id: user.id, data });

      if (!res.error && !res.errors) {
        const payload = {
          id: user.id.toString(),
          email: data.email,
          profession: data?.profession,
          role: data?.role,
          levelOfTraining: data?.levelOfTraining,
          department: data?.department,
          division: data?.division,
          location: data?.location,
          title: data?.title,
          specialty: data?.specialty,
          rotation: data?.rotation,
          language: data?.language,
          timezone: data?.timezone,
        };

        if (!user.profileCompletedAt) {
          analyticsTriggers?.completeProfile?.(payload);
        } else {
          analyticsTriggers?.updateProfile?.(payload);
        }

        openNotification();
        onFinish();
        setLoading(false);
        return;
      }

      api.error({
        message: lang.NOTIFICATION_ERROR,
        placement: 'bottomRight',
      });

      setLoading(false);
    },
    [onFinish, openNotification, onUpdate, user, analyticsTriggers, lang, api],
  );

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    values,
    errors,
    touched,
    dirty,
    isValid,
  } = useFormik({
    validationSchema: schema,
    initialValues: {
      ...userData,
      ...professional,
      profession,
      timezone: user?.timezone ?? DateTime.local().zoneName,
      language: user?.language ?? selectedLang,
    },
    onSubmit: updateProfile,
    enableReinitialize: true,
  });

  const handleDependentFieldChange = useCallback(
    (field, dependentField, value) => {
      if (!value || value !== values[field]) {
        setFieldValue(dependentField, '');
      }
      setFieldValue(field, value);
    },
    [setFieldValue, values],
  );

  const onChange = useCallback(
    (field, value) => {
      if (field === 'profession') {
        handleDependentFieldChange('profession', 'role', value);
        handleDependentFieldChange('role', 'levelOfTraining');
      }

      if (field === 'role') {
        handleDependentFieldChange('role', 'levelOfTraining', value);

        if (values.role === ROLES_DOCTOR.RESIDENT) {
          onChange('division', []);
        } else {
          onChange('rotation', '');
        }
      }

      setFieldValue(field, value);
    },
    [handleDependentFieldChange, setFieldValue, values.role],
  );

  const changeTNCApproval = useCallback(() => {
    onChange('approveTermsAndConditions', !values.approveTermsAndConditions);
  }, [onChange, values.approveTermsAndConditions]);

  const {
    departmentOptions,
    divisionOptions,
    specialtyOptions,
    rotationOptions,
    professionOptions,
    roleOptions,
    levelOftrainingOptions,
    languageOptions,
  } = useProfileOptions({
    profession: values.profession,
    role: values.role,
    department: values.department,
  });

  const { workspacesOptions } = useProfileLang();

  const isRoleRequired = PROFESSIONS_WITH_ROLES.includes(values.profession);
  const isLevelOfTrainingRequired = ROLES_WITH_LEVEL_OF_TRAINING.includes(values.role);
  const isUser = type === PROFILE_TYPES.USER || type === PROFILE_TYPES.SELF;

  const formComponents = [
    {
      Component: TextInput,
      props: {
        label:
          type === PROFILE_TYPES.SELF || type === PROFILE_TYPES.CONTACT ? (
            <RequiredIndication label={lang.FIRST_NAME} />
          ) : (
            lang.FIRST_NAME
          ),
        placeholder: lang.FIRST_NAME,
        name: 'firstName',
        value: values.firstName,
        error: errors.firstName,
        touched: touched.firstName,
        onChange: handleChange,
        onBlur: handleBlur,
      },
    },
    {
      Component: TextInput,
      props: {
        label:
          type === PROFILE_TYPES.SELF ? (
            <RequiredIndication label={lang.LAST_NAME} />
          ) : (
            lang.LAST_NAME
          ),
        placeholder: lang.LAST_NAME,
        name: 'lastName',
        value: values.lastName,
        error: errors.lastName,
        touched: touched.lastName,
        onChange: handleChange,
        onBlur: handleBlur,
      },
    },
    {
      Component: TextInput,
      props: {
        allowClear: true,
        label: lang.EMAIL,
        placeholder: lang.EMAIL,
        name: 'email',
        value: values.email,
        error: errors.email,
        touched: touched.email,
        onChange: handleChange,
        onBlur: handleBlur,
        disabled: isUser,
      },
    },
    {
      Component: TextInput,
      props: {
        allowClear: true,
        label: lang.PHONE,
        placeholder: lang.PHONE,
        name: 'phone',
        value: values.phone,
        error: errors.phone,
        touched: touched.phone,
        onChange: handleChange,
        onBlur: handleBlur,
      },
    },
    {
      Component: TextInput,
      props: {
        allowClear: true,
        label: lang.PAGER,
        placeholder: lang.PAGER,
        name: 'pager',
        value: values.pager,
        error: errors.pager,
        touched: touched.pager,
        onChange: handleChange,
        onBlur: handleBlur,
      },
    },
    {
      Component: TextInput,
      props: {
        allowClear: true,
        label: lang.CISCO,
        placeholder: lang.CISCO,
        name: 'cisco',
        value: values.cisco,
        error: errors.cisco,
        touched: touched.cisco,
        onChange: handleChange,
        onBlur: handleBlur,
      },
    },
    {
      Component: SelectBox,
      props: {
        label:
          type === PROFILE_TYPES.SELF ? (
            <RequiredIndication label={lang.DEPARTMENT} />
          ) : (
            lang.DEPARTMENT
          ),
        placeholder: lang.DEPARTMENT,
        name: 'department',
        value: values.department,
        error: errors.department,
        onChange: onChange,
        options: departmentOptions,
      },
    },
    {
      Component: SelectBox,
      props: {
        label:
          type === PROFILE_TYPES.SELF ? (
            <RequiredIndication label={lang.PROFESSION} />
          ) : (
            lang.PROFESSION
          ),
        placeholder: lang.PROFESSION,
        name: 'profession',
        value: values.profession,
        error: errors.profession,
        onChange: onChange,
        options: professionOptions,
      },
      hide: !isUser,
    },
    {
      Component: SelectBox,
      props: {
        label: isRoleRequired ? <RequiredIndication label={lang.ROLE} /> : lang.ROLE,
        placeholder: lang.ROLE,
        name: 'role',
        value: values.role,
        error: errors.role,
        onChange: onChange,
        options: roleOptions ?? [],
        disabled: !roleOptions,
      },
      hide: !isUser,
    },
    {
      Component: SelectBox,
      props: {
        label: isLevelOfTrainingRequired ? (
          <RequiredIndication label={lang.LEVEL_OF_TRAINING} />
        ) : (
          lang.LEVEL_OF_TRAINING
        ),
        placeholder: lang.LEVEL_OF_TRAINING,
        name: 'levelOfTraining',
        value: values.levelOfTraining,
        error: errors.levelOfTraining,
        onChange: onChange,
        options: levelOftrainingOptions ?? [],
        disabled: !levelOftrainingOptions,
      },
      hide: !isUser,
    },
    {
      Component: SelectBox,
      props: {
        allowClear: true,
        label: lang.ROTATION,
        placeholder: lang.ROTATION,
        name: 'rotation',
        value: values.rotation,
        error: errors.rotation,
        onChange: onChange,
        options: rotationOptions,
      },
      hide: values.role !== ROLES_DOCTOR.RESIDENT || !isUser,
    },
    {
      Component: SelectBox,
      props: {
        allowClear: true,
        multiple: true,
        label: lang.DIVISION,
        placeholder: lang.DIVISION,
        name: 'division',
        value: values.division,
        error: errors.division,
        onChange: onChange,
        options: divisionOptions,
      },
      hide: values.role === ROLES_DOCTOR.RESIDENT || !isUser,
    },
    {
      Component: LabelsSelector,
      props: {
        allowClear: true,
        label: lang.LOCATION,
        value: values.location,
        error: errors.location,
        placeholder: lang.LOCATION,
        name: 'location',
        onChange: onChange,
        wrapperClass: styles.location,
      },
      type: [PROFILE_TYPES.USER, PROFILE_TYPES.SELF],
    },
    {
      Component: SelectBox,
      props: {
        allowClear: true,
        multiple: true,
        label: lang.SPECIALTY,
        placeholder: lang.SPECIALTY,
        name: 'specialty',
        onChange: onChange,
        options: specialtyOptions,
        value: values.specialty,
        error: errors.specialty,
      },
      hide: !isUser,
    },
    {
      Component: TextInput,
      props: {
        allowClear: true,
        label: lang.TITLE,
        placeholder: lang.TITLE,
        name: 'title',
        value: values.title,
        error: errors.title,
        touched: touched.title,
        onChange: handleChange,
        onBlur: handleBlur,
      },
      hide: !isUser,
    },
    {
      Component: SelectBox,
      props: {
        label: lang.LANGUAGE,
        placeholder: lang.LANGUAGE,
        name: 'language',
        value: values.language,
        error: errors.language,
        onChange: onChange,
        options: languageOptions,
      },
      hide: type !== PROFILE_TYPES.SELF,
    },
    {
      Component: SelectBox,
      props: {
        label: lang.WORKSPACE,
        placeholder: lang.WORKSPACE,
        name: 'workspaceId',
        value: values.workspaceId,
        error: errors.workspaceId,
        onChange: onChange,
        options: workspacesOptions.map(workspace => ({ id: workspace.id, value: workspace.name })),
      },
      hide: type !== PROFILE_TYPES.CONTACT,
    },
  ];

  const topSectionFieldsOrder = ['firstName', 'lastName'];
  const userFieldsOrder = [
    'email',
    'title',
    'department',
    'phone',
    'profession',
    'rotation',
    'division',
    'cisco',
    'role',
    'location',
    'pager',
    'levelOfTraining',
    'specialty',
    'language',
    'workspaceId',
  ];

  const renderNotificationButtons = () => (
    <div className={styles.notificationButtons}>
      <Button onClick={resetForm}>{lang.DISCARD}</Button>
      <Button type="primary" onClick={handleSubmit} disabled={loading || !dirty || !isValid}>
        {lang.SAVE_CHANGES}
      </Button>
    </div>
  );

  const renderTopNotification = () => {
    if (type !== PROFILE_TYPES.SELF) return null;

    return dirty || isPopup ? (
      <Notification
        message={isPopup ? lang.POPUP_TITLE : lang.CHANGES_MADE}
        renderButtons={renderNotificationButtons}
      />
    ) : null;
  };

  const renderTopSectionFields = () => (
    <>
      {type === PROFILE_TYPES.SELF && (
        <Col span={18}>
          <ScreenTitle title={lang.PAGE_TITLE} containerStyle={styles.noMargin} />
          <Divider />
        </Col>
      )}

      <Col span={24}>
        <Row gutter={50}>
          {topSectionFieldsOrder
            .map(field => formComponents.find(f => f.props.name === field))
            .map(({ Component, props }, index) => (
              <Col span={6} key={index}>
                <Component {...props} />
              </Col>
            ))}

          {type !== PROFILE_TYPES.SELF && (
            <Col span={6} offset={6} className={styles.buttons}>
              <Button onClick={onFinish}>{lang.DISMISS}</Button>
              <Button
                type="primary"
                onClick={handleSubmit}
                disabled={loading || !dirty || !isValid}
              >
                {lang.SAVE_CHANGES}
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </>
  );

  const renderFormFields = () =>
    userFieldsOrder
      .map(field => formComponents.find(f => f.props.name === field))
      .map(({ Component, props: initialProps, type: t, hide }, index) => {
        const props = {
          ...initialProps,
          disabled: initialProps.disabled || (!!t && !t?.includes(type)),
        };

        if (hide) return null;

        return (
          <Col span={8} key={index}>
            <Component {...props} />
          </Col>
        );
      });

  const renderBottomSection = () => {
    if (type === PROFILE_TYPES.CONTACT || isPopup) return null;

    return <ProfessionalProfileManager user={user} canEdit={canEdit} />;
  };

  const renderTNC = () => {
    if (!enforceTNC) return null;

    return (
      <>
        <div className={styles.tnc}>
          <Checkbox onChange={changeTNCApproval} className={styles.tncCheckbox} />
          <ReactMarkdown linkTarget="_blank" className={styles.disclaimer}>
            {lang.TERMS_AND_CONDITIONS_DISCLAIMER}
          </ReactMarkdown>
        </div>
        <span className={styles.error}>{errors.approveTermsAndConditions}</span>
      </>
    );
  };

  if (!user) {
    return <Loader />;
  }

  return (
    <>
      {contextHolder}
      <ProfileLayoutComponent
        renderTopNotification={renderTopNotification}
        renderTopSection={renderTopSectionFields}
        renderMainSection={renderFormFields}
        renderBottomSection={renderBottomSection}
        renderFooter={renderTNC}
      />
    </>
  );
};

export { UserProfileComponent, PROFILE_TYPES };
