import { INTERNAL_ROUTING, ROUTES } from 'shared/constants';
import { history } from '../store/store';

export const routeWithQueryParam = ({ key, value = null }) => {
  const path = history.location.pathname;
  history.push(value ? path.concat(`?${key}=${value}`) : path);
};

export const getQueryParam = ({ key }) => new URLSearchParams(history.location.search).get(key);

export const redirectToRestrictedAccessPage = ({ entity, id }) => {
  const searchParams = new URLSearchParams();
  searchParams.set(INTERNAL_ROUTING.QUERY_PARAMS.RESTRICTED_ENTITY, entity);
  searchParams.set(INTERNAL_ROUTING.QUERY_PARAMS.RESTRICTED_ID, id);
  history.replace({ pathname: ROUTES.RESTRICTED, search: searchParams.toString() });
};
