import styles from './copy-workspace-settings.module.scss';
import { Switch } from 'antd';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { getWorkspaces } from 'shared/store/selectors/workspace.selector';
import { Button, Divider, Select } from 'src/components/design-system';
import { WORKSPACE_COPY_SCHEMA } from '../workspace-editor/workspace.schema';

/**
 * Copy workspace settings component
 * @param {object} props
 * @param {import('../../types').Workspace} props.workspaceToCopyTo the workspace to copy to
 * @param {(workspace: import('../../types').Workspace) => void} props.onSubmit function to submit the changes
 * @param {() => void} props.onChange function to cancel the changes
 */
export function CopyWorkspaceSettings({ workspaceToCopyTo, onSubmit, onCancel }) {
  const workspaces = useSelector(getWorkspaces);
  const lang = useSelector(getLang('SETTINGS'));
  const options = useMemo(() => {
    return workspaces
      .filter(workspace => workspace.id !== workspaceToCopyTo.id)
      .map(workspace => ({
        value: workspace.id,
        label: workspace.name,
      }));
  }, [workspaces, workspaceToCopyTo]);

  const cloneWorkspace = ({
    workspaceId,
    copyCalendarUrl,
    copyQuickLinks,
    copyScheduleUrls,
    copyDashboardBanner,
  }) => {
    const fromWorkspace = workspaces.find(workspace => workspace.id === workspaceId);

    if (!fromWorkspace) {
      return;
    }

    const { operational } = fromWorkspace;

    return {
      ...workspaceToCopyTo,
      operational: {
        calendarUrl: copyCalendarUrl
          ? operational.calendarUrl
          : workspaceToCopyTo.operational.calendarUrl,
        quickLinks: copyQuickLinks
          ? operational.quickLinks
          : workspaceToCopyTo.operational.quickLinks,
        scheduleUrls: copyScheduleUrls
          ? operational.scheduleUrls
          : workspaceToCopyTo.operational.scheduleUrls,
        dashboardBanner: copyDashboardBanner
          ? operational.dashboardBanner
          : workspaceToCopyTo.operational.dashboardBanner,
      },
    };
  };

  const applyChanges = (values, { resetForm }) => {
    onSubmit(cloneWorkspace(values));
    resetForm();
  };

  const { values, errors, dirty, isValid, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      workspaceId: '',
      copyCalendarUrl: false,
      copyQuickLinks: false,
      copyScheduleUrls: false,
      copyDashboardBanner: false,
    },
    enableReinitialize: true,
    validationSchema: WORKSPACE_COPY_SCHEMA,
    onSubmit: applyChanges,
  });

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.fields}>
        <Select
          value={values.workspaceId}
          error={errors.workspaceId}
          options={options}
          label={lang.COPY_FROM_WORKSPACE_SELECT_LABEL}
          name="workspaceId"
          onChange={setFieldValue}
        />

        <div className={styles.switchWrapper}>
          <Switch
            style={{ width: '2rem' }}
            checked={values.copyCalendarUrl}
            onChange={checked => setFieldValue('copyCalendarUrl', checked)}
          />
          <label>{lang.COPY_CALENDAR_URL}</label>
        </div>
        <div className={styles.switchWrapper}>
          <Switch
            style={{ width: '2rem' }}
            checked={values.copyQuickLinks}
            onChange={checked => setFieldValue('copyQuickLinks', checked)}
          />
          <label>{lang.COPY_QUICK_LINKS}</label>
        </div>

        <div className={styles.switchWrapper}>
          <Switch
            style={{ width: '2rem' }}
            checked={values.copyScheduleUrls}
            onChange={checked => setFieldValue('copyScheduleUrls', checked)}
          />
          <label>{lang.COPY_SCHEDULE_URLS}</label>
        </div>

        <div className={styles.switchWrapper}>
          <Switch
            style={{ width: '2rem' }}
            checked={values.copyDashboardBanner}
            onChange={checked => setFieldValue('copyDashboardBanner', checked)}
          />
          <label>{lang.COPY_DASHBOARD_BANNER}</label>
        </div>
      </div>

      <Divider />

      <div className={styles.actions}>
        <Button onClick={handleCancel}>{lang.CANCEL}</Button>
        <Button type="primary" htmlType="submit" disabled={!isValid || !dirty}>
          {lang.COPY_APPLY}
        </Button>
      </div>
    </form>
  );
}
