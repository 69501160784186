import {
  KNOWLEDGE_UPDATE_CATEGORY,
  KNOWLEDGE_DELETE_CATEGORY,
  KNOWLEDGE_CREATE_CATEGORY,
  KNOWLEDGE_UPDATE_SUBCATEGORIES,
  KNOWLEDGE_UPDATE_CATEGORY_LOCATION,
} from '.';
import knowledgeServiceApi from '../../api/knowledge.service.api';

export const updateCategory =
  ({ id, title, subtitle, ownerIds, path, tags, options, ...object }) =>
  async dispatch => {
    let change = { id, ...object };

    if (title) change.title = title;
    if (subtitle) change.subtitle = subtitle;
    if (ownerIds) change.ownerIds = ownerIds;
    if (path) change.path = path;
    if (tags) change.tags = tags;
    if (options) change.options = options;

    let res = await knowledgeServiceApi.updateCategory(change);

    if (res.error) {
      // TODO: add monitor
      return false;
    }

    dispatch({
      type: KNOWLEDGE_UPDATE_CATEGORY,
      payload: change,
    });
  };

export const moveKnowledgeCategory =
  ({ id, categoryId, sourceId }) =>
  async dispatch => {
    let res = await knowledgeServiceApi.moveKnowledgeCategory({
      id,
      categoryId,
      sourceId,
    });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_UPDATE_CATEGORY_LOCATION,
    });
  };

export const deleteCategory =
  ({ id }) =>
  async dispatch => {
    let res = await knowledgeServiceApi.deleteCategory({ id });

    if (res.error) {
      // TODO: add monitor
      return false;
    }

    dispatch({
      type: KNOWLEDGE_DELETE_CATEGORY,
      payload: { id },
    });
  };

export const createCategory = data => async (dispatch, getState) => {
  const workspaceId = getState().workspace.selected;

  let res = await knowledgeServiceApi.createCategory({ data, workspaceId });

  if (res.error) {
    // TODO: add monitor
    return false;
  }

  dispatch({
    type: KNOWLEDGE_CREATE_CATEGORY,
    payload: {
      category: res.data,
      path: data.path,
    },
  });

  return res.data;
};

export const updateCategorySubcategories =
  ({ id, categoryId, index }) =>
  async dispatch => {
    let res = await knowledgeServiceApi.updateCategorySubcategories({ id, categoryId, index });

    if (res.error) {
      // TODO: add monitor
      return false;
    }

    dispatch({
      type: KNOWLEDGE_UPDATE_SUBCATEGORIES,
      payload: { id, categoryId, index },
    });
  };
