import {
  DIVISION,
  PROFESSIONS_WITH_ROLES,
  ROLES_WITH_LEVEL_OF_TRAINING,
  SPECIALTY,
} from 'shared/constants';
import * as Yup from 'yup';

export const CONTACT_SCHEMA = Yup.object().shape({
  email: Yup.string().email(),
  firstName: Yup.string().nullable().required('Required!'),
  lastName: Yup.string(),
  phone: Yup.string().nullable(),
  pager: Yup.string().nullable(),
  cisco: Yup.string().nullable(),
  department: Yup.string(),
  workspaceId: Yup.string().nullable(),
});

export const USER_SCHEMA = Yup.object().shape({
  email: Yup.string().email('Please insert a valid email').required('Required!'),
  firstName: Yup.string().nullable(),
  lastName: Yup.string(),
  phone: Yup.string().nullable(),
  pager: Yup.string().nullable(),
  cisco: Yup.string().nullable(),
  profession: Yup.string(),
  role: Yup.string().when('profession', ([profession], schema) =>
    PROFESSIONS_WITH_ROLES.includes(profession) ? schema.required('Required!') : schema.nullable(),
  ),
  levelOfTraining: Yup.string().when('role', ([role], schema) =>
    ROLES_WITH_LEVEL_OF_TRAINING.includes(role) ? schema.required('Required!') : schema.nullable(),
  ),
  department: Yup.string().nullable(),
  rotation: Yup.string().nullable(),
  division: Yup.array()
    .of(Yup.string().oneOf([...Object.values(DIVISION)]))
    .nullable(),
  location: Yup.array().of(Yup.string()).nullable(),
  specialty: Yup.array()
    .of(Yup.string().oneOf([...Object.values(SPECIALTY)]))
    .nullable(),
  title: Yup.string().nullable(),
});

export const MY_PROFILE_SCHEMA = Yup.object().shape({
  firstName: Yup.string().required('Required!'),
  lastName: Yup.string().required('Required!'),
  phone: Yup.string(),
  pager: Yup.string(),
  cisco: Yup.string(),
  profession: Yup.string().required('Required!'),
  language: Yup.string().required('Required!'),
  timezone: Yup.string().required('Required!'),
  role: Yup.string().when('profession', ([profession], schema) =>
    PROFESSIONS_WITH_ROLES.includes(profession) ? schema.required('Required!') : schema.nullable(),
  ),
  levelOfTraining: Yup.string().when('role', ([role], schema) =>
    ROLES_WITH_LEVEL_OF_TRAINING.includes(role) ? schema.required('Required!') : schema.nullable(),
  ),
  department: Yup.string().required('Required!'),
  rotation: Yup.string().nullable(),
  division: Yup.array()
    .of(Yup.string().oneOf([...Object.values(DIVISION)]))
    .nullable(),
  location: Yup.array().of(Yup.string()).nullable(),
  specialty: Yup.array()
    .of(Yup.string().oneOf([...Object.values(SPECIALTY)]))
    .nullable(),
  title: Yup.string().nullable(),
});
