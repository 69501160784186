import React, { useEffect } from 'react';
import _ from 'lodash';
import styles from './search.page.module.scss';
import { Lang } from 'shared/constants';
import { KnowledgeWrapperComponent } from '../knowledge/components/knowledge-row';
import { PandaAI } from '../../../images';
import { NotFound } from '../../../components/common';
import { Loader } from '../../../components/core/loader/Loader.component';
import { KnowledgeDeletePopup } from '../knowledge/components/knowledge-row/knowledgeDelete';
import { SEARCH_TYPES_KEYS, SEARCH_PAGE_TYPES } from 'shared/constants';
import { KnowledgeEditorPopup } from '../knowledge/components/knowledge-row/knowledgeEditor';
import { SearchType } from '../../../components/searchType';
import { LabValue } from '../lab/components/lab-value/lab-value';
import { LabValueDelete } from '../lab/components/lab-value/lab-value-delete';
import { LabValueEditor } from '../lab/components/lab-value/lab-value-editor';
import { KnowledgeMovePopup } from '../knowledge/components/knowledge-row/knowledgeMove';
import { AdmissionCategoryDelete } from '../admission/components/admission-category/admission-category-delete';
import { AdmissionCategoryEditor } from '../admission/components/admission-category/admission-category-editor';
import { AdmissionCategoryItem } from '../admission/components/admission-category/admission-category-item';
import { useTagFilters } from '../../../components/search';
import { filterByTags } from 'shared/utils/search.utils';
import analyticsService from '../../../helpers/analytics.service';
import { EVENTS } from 'shared/constants/analytics';
import { ContactItemComponent } from '../../../components/contact-item/contact-item.component';
import { useSelector } from 'react-redux';
import { isImprovedSearchEnabled } from 'shared/store/selectors/features.selector';
import { useSearchResultsPreparation } from 'shared/hooks/search.hooks';
import { generateUpToDateLink } from 'shared/utils';
import { notification } from 'antd';
import { GiveFeedbackReport } from 'src/components/issue-report/give-feedback-report';
import { SearchGroups } from './search-renderers/search-groups.component';

export const SEARCH_TYPE = {
  REGULAR: 'regular',
  EXTENDED: 'extended',
};

const SearchPageComponent = React.memo(
  ({
    lang,
    langScreen,
    results,
    searching,
    term,
    adminMode,
    lastUpdated,
    knowledgeItem,
    id,
    addSearchFilters,
    isContactsEnabled,
    sessionId,
    activeWorkspaceId,
  }) => {
    const [deleteKnowledgeItem, setDeleteKnowledgeItem] = React.useState(null);
    const [knowledgeEntityEditor, setKnowledgeEntityEditor] = React.useState(knowledgeItem);
    const [deleteLabValue, setDeleteLabValue] = React.useState(null);
    const [labValueEntity, setLabValueEntity] = React.useState(null);
    const [deleteChecklist, setDeleteChecklist] = React.useState(null);
    const [checklistEntity, setChecklistEntity] = React.useState(null);
    const [searchType, setSearchType] = React.useState(null);
    const [knowledgeEntityMove, setKnowledgeEntityMove] = React.useState(null);
    const userId = useSelector(({ auth }) => auth.user.id);
    const [showIssueReportPopup, setShowIssueReportPopup] = React.useState(false);
    const [showExtendedResults, setShowExtendedResults] = React.useState(false);
    const [notificationApi, contextHolder] = notification.useNotification();

    const openIssueReportPopup = () => {
      setShowIssueReportPopup(true);
    };

    const closeIssueReportPopup = () => {
      setShowIssueReportPopup(false);
    };

    const { FilterComponent, filters } = useTagFilters({ results, term });
    const filtersByTags = filterByTags({ filters });

    const isImprovedSearch = useSelector(isImprovedSearchEnabled);

    const searchTypes = SEARCH_PAGE_TYPES(lang).filter(
      ({ type }) => isContactsEnabled || type !== SEARCH_TYPES_KEYS.contacts,
    );

    const { prepareResults } = useSearchResultsPreparation(
      {
        filtersByTags,
      },
      searchType,
      adminMode,
      isContactsEnabled,
    );

    React.useEffect(() => {
      setSearchType(null);
      setShowExtendedResults(false);
      if (!term || !results) {
        return;
      }
      const { knowledge = [], lab = [], admission = [], contacts = [] } = results || {};
      const itemsExist = knowledge.length || lab.length || admission.length || contacts.length;
      analyticsService.track(itemsExist ? EVENTS.SEARCH.SEARCHING : EVENTS.SEARCH.NOT_FOUND, {
        searchTerm: term,
        ...Object.fromEntries(Object.entries(filters).map(([key, value]) => [key, [...value]])),
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term, filters]);

    React.useEffect(() => {
      setKnowledgeEntityEditor(knowledgeItem);
    }, [knowledgeItem]);

    // Delete knowledge items
    const deleteKnowledgeAction = React.useCallback(({ id, title }) => {
      // TODO: analytics
      setDeleteKnowledgeItem({ id, title });
    }, []);

    const onFinishDelete = React.useCallback(() => {
      setDeleteKnowledgeItem(null);
    }, []);

    // Editor
    const onFinishEditor = React.useCallback(() => {
      setKnowledgeEntityEditor(null);
    }, []);

    const editOrCreateKnowledge = React.useCallback(({ item = {} }) => {
      setKnowledgeEntityEditor(item);
    }, []);

    // Delete value items
    const deleteValueAction = React.useCallback(({ id, title }) => {
      // TODO: analytics
      setDeleteLabValue({ id, title });
    }, []);

    const onFinishDeleteLab = React.useCallback(() => {
      setDeleteLabValue(null);
    }, []);

    // Editor
    const onFinishEditorLab = React.useCallback(() => {
      setLabValueEntity(null);
    }, []);

    const editOrCreateLabValue = React.useCallback(({ item = {} }) => {
      setLabValueEntity(item);
    }, []);
    // Move knowledge items
    const moveKnowledgeAction = React.useCallback(({ id, title, ownerIds }) => {
      // TODO: analytics
      setKnowledgeEntityMove({ id, title, ownerIds });
    }, []);

    const onFinishMove = React.useCallback(() => {
      setKnowledgeEntityMove(null);
    }, []);

    // checklist delete
    const deleteChecklistAction = React.useCallback(({ id, title }) => {
      // TODO: analytics
      setDeleteChecklist({ id, title });
    }, []);

    const onFinishDeleteChecklist = React.useCallback(() => {
      setDeleteChecklist(null);
    }, []);

    // checklist editor
    const onFinishEditChecklist = React.useCallback(() => {
      setChecklistEntity(null);
    }, []);

    const editOrCreateChecklist = React.useCallback(
      ({ id, title, ownerId, path, tags, type, keywords }) => {
        setChecklistEntity({ id, title, ownerId, path, tags, type, keywords });
      },
      [],
    );

    const { resultsCount, knowledgeItems, labItems, admissionItems, contactItems } =
      prepareResults(results);

    // == Analytics events
    /**
     * Track search session start
     */
    React.useEffect(() => {
      // If clean session
      if (!sessionId) {
        return;
      }

      console.debug('[Search] session start', { sessionId, resultsCount, term });

      analyticsService.track(EVENTS.SEARCH.SESSION_START, {
        term,
        sessionId,
        resultsCount,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId]);

    /**
     * Track click on result
     * @param {Object} item - result item details
     * @param {number} index - result index
     * @param {string} resultType - result type
     * @param {Object} options - Options for analytics
     */
    const onResultClicked = React.useCallback(
      (item, index, resultType, options) => {
        const eventDetails = {
          term,
          sessionId,
          index: index + 1,
          resultsCount,
          score: item?.score || 0,
          resultType,
          ...item,
          ...options,
        };

        analyticsService.track(EVENTS.SEARCH.RESULT_CLICKED, eventDetails);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [sessionId, resultsCount],
    );

    // Prepare render by type
    const renderByType = React.useMemo(
      () => ({
        [SEARCH_TYPES_KEYS.documents]: ({ item }, index, options = {}) => (
          <KnowledgeWrapperComponent
            key={item.id}
            item={item}
            draggable={false}
            adminMode={adminMode}
            onDelete={deleteKnowledgeAction}
            onUpdate={editOrCreateKnowledge}
            onMove={moveKnowledgeAction}
            onClickEvent={event =>
              onResultClicked(event, index, SEARCH_TYPES_KEYS.documents, options)
            }
            light
            showLabels
            showSpecialty
            hideFavorites={item.workspaceId !== activeWorkspaceId}
          />
        ),
        [SEARCH_TYPES_KEYS.compendium]: ({ item }) => (
          <LabValue
            key={item.id}
            id={item.id}
            item={item}
            draggable={false}
            adminMode={adminMode}
            deleteValue={deleteValueAction}
            editValue={editOrCreateLabValue}
            light
            showSpecialty
          />
        ),
        [SEARCH_TYPES_KEYS.admission]: ({ item }, index, options = {}) => (
          <AdmissionCategoryItem
            key={item.id}
            item={item}
            deleteCategory={deleteChecklistAction}
            editCategory={editOrCreateChecklist}
            onClickEvent={event =>
              onResultClicked(event, index, SEARCH_TYPES_KEYS.admission, options)
            }
            light
          />
        ),
        [SEARCH_TYPES_KEYS.contacts]: ({ item }, index, options = {}) => (
          <ContactItemComponent
            key={item.id}
            contact={item}
            onClickEvent={event =>
              onResultClicked(event, index, SEARCH_TYPES_KEYS.contacts, options)
            }
            hideFavorites={item.workspaceId !== activeWorkspaceId}
          />
        ),
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [adminMode, sessionId, onResultClicked, activeWorkspaceId],
    );

    const renderResultsRegular = React.useCallback(() => {
      // prepare of extended results
      const extendedSearch = isImprovedSearch ? prepareResults(results.extendedSearch) : {};

      // No results to display
      const isEmptyState =
        resultsCount === 0 && (!isImprovedSearch || extendedSearch?.resultsCount === 0);

      const allResults = { contactItems, knowledgeItems, labItems, admissionItems };

      const options = {
        searchType: SEARCH_TYPE.REGULAR,
      };

      return (
        <>
          <div className={styles.searchResults} key={lastUpdated}>
            <SearchType searchType={searchType} types={searchTypes} onChange={setSearchType} />

            {isEmptyState && <NotFound className={styles.notFound} text={langScreen.NO_ITEMS} />}

            {resultsCount > 0 && (
              <h5>
                {langScreen.ITEMS_FOUND.replace("'{number}'", resultsCount).replace('{term}', term)}
              </h5>
            )}
            {!isEmptyState && (
              <SearchGroups
                results={allResults}
                renderByType={renderByType}
                options={options}
                extendedSearch={extendedSearch}
              />
            )}

            {renderUpToDateLink()}
          </div>
          <div className={styles.filterBox}>
            <FilterComponent />
          </div>
        </>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adminMode, results, searchType, showExtendedResults, prepareResults]);

    const renderResults = React.useCallback(() => {
      if (!searching && term === '') {
        return (
          <div className={styles.emptyRoot}>
            <h4 className={styles.emptyHeader}>{langScreen.CALL_TO_ACTION_SEARCH}</h4>
            <img
              src={PandaAI}
              className={styles.emptyImage}
              alt={langScreen.CALL_TO_ACTION_SEARCH}
            />
          </div>
        );
      }

      if (_.isNull(results) || searching) {
        return (
          <div className={styles.loader}>
            <Loader />
          </div>
        );
      }

      return renderResultsRegular();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      isImprovedSearch,
      adminMode,
      lastUpdated,
      results,
      searchType,
      searching,
      term,
      showExtendedResults,
    ]);

    const renderUpToDateLink = React.useCallback(() => {
      const [first, upToDateLink, third, reportIssueLink] =
        langScreen.UP_TO_DATE_LINK.split(/{(.*?)}/);

      return (
        <div className={styles.upToDateLink}>
          <p>
            {first}
            <a target="_blank" rel="noopener noreferrer" href={generateUpToDateLink(term, userId)}>
              {upToDateLink}
            </a>
            {third}
            <span onClick={openIssueReportPopup}>{reportIssueLink}</span>
          </p>
        </div>
      );
    }, [term, userId, langScreen.UP_TO_DATE_LINK]);

    useEffect(() => {
      addSearchFilters(filters);
    }, [addSearchFilters, filters]);

    return (
      <div className={styles.root}>
        {showIssueReportPopup && (
          <GiveFeedbackReport close={closeIssueReportPopup} notificationApi={notificationApi} />
        )}
        {contextHolder}
        {renderResults()}

        {deleteKnowledgeItem && (
          <KnowledgeDeletePopup entity={deleteKnowledgeItem} onFinish={onFinishDelete} />
        )}

        {knowledgeEntityEditor && (
          <KnowledgeEditorPopup knowledge={knowledgeEntityEditor} onFinish={onFinishEditor} />
        )}

        {deleteLabValue && <LabValueDelete entity={deleteLabValue} onFinish={onFinishDeleteLab} />}

        {labValueEntity && <LabValueEditor value={labValueEntity} onFinish={onFinishEditorLab} />}

        {knowledgeEntityMove && (
          <KnowledgeMovePopup
            categoryId={id}
            item={knowledgeEntityMove}
            onFinish={onFinishMove}
            isOpen={!!knowledgeEntityMove}
            enableCreateLink
          />
        )}

        {deleteChecklist && (
          <AdmissionCategoryDelete entity={deleteChecklist} onFinish={onFinishDeleteChecklist} />
        )}
        {checklistEntity && (
          <AdmissionCategoryEditor entity={checklistEntity} onFinish={onFinishEditChecklist} />
        )}
      </div>
    );
  },
);

SearchPageComponent.defaultProps = {
  results: null,
  searching: false,
  lang: Lang.SEARCH_TYPES,
  langScreen: Lang.SEARCH_SCREEN,
  term: '',
  adminMode: false,
  lastUpdated: Date.now(),
  knowledgeItem: null,
  addSearchFilters: () => {},
};

export { SearchPageComponent };
