import { connect } from 'react-redux';
import { NewsFeedListComponent } from './news-feed-list.component';

const mapStateToProps = ({ application, communication, lang }) => ({
  items: communication?.newsFeedItems,
  adminMode: application.adminMode,
  lang: lang.NEWS_FEED,
});

export const NewsFeedList = connect(mapStateToProps)(NewsFeedListComponent);
