import API from './API';
import { DELETE, GET, POST, PUT } from '../utils/Executer';
import { LAB_SERVICE_URL as servicePath } from '../constants/APIconstants';

class LabServiceAPI {
  async getCategories() {
    return await API.client.execute(GET, `${servicePath}categories`);
  }

  async getCategoryById({ id }) {
    return await API.client.execute(GET, `${servicePath}categories/${id}`);
  }

  async getValues() {
    return await API.client.execute(GET, `${servicePath}values`);
  }

  // lab Category
  async updateCategory({ id, title, ownerIds, tags, drugClass, path }) {
    return await API.client.execute(PUT, `${servicePath}categories/${id}`, {
      title,
      ownerIds,
      tags,
      drugClass,
      path,
    });
  }

  async moveLabCategory({ id, categoryId, sourceId }) {
    return await API.client.execute(PUT, `${servicePath}categories/${id}/move`, {
      categoryId,
      sourceId,
    });
  }

  async deleteCategory({ id }) {
    return await API.client.execute(DELETE, `${servicePath}categories/${id}`);
  }

  async updateCategorySubcategories({ id, categoryId, index }) {
    return await API.client.execute(POST, `${servicePath}categories/${id}/subcategories/order`, {
      categoryId,
      index,
    });
  }

  async createCategory({ data, workspaceId }) {
    return await API.client.execute(
      POST,
      `${servicePath}categories?workspace=${workspaceId}`,
      data,
    );
  }

  // Create value
  async createValue({ data, workspaceId }) {
    return await API.client.execute(POST, `${servicePath}values?workspace=${workspaceId}`, data);
  }

  async updateLabItem({ id, item }) {
    return await API.client.execute(PUT, `${servicePath}values/${id}`, { item });
  }

  async deleteLabItem({ id }) {
    return await API.client.execute(DELETE, `${servicePath}values/${id}`);
  }

  async updateValueOrder({ id, itemId, index }) {
    return await API.client.execute(POST, `${servicePath}categories/${id}/values/order`, {
      itemId,
      index,
    });
  }
}

export default new LabServiceAPI();
