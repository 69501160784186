import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { KnowledgeContentPreviewComponent } from './knowledge-content-preview/knowledge-content-preview.component';
import { KnowledgeContentEditor } from './knowledge-content-editor';
import { getKnowledgeItemById } from 'shared/store/selectors/knowledge.selector';
import { useEffect } from 'react';
import { fetchKnowledgeItemContent } from 'shared/store/actions/knowledge.items.actions';
import { KNOWLEDGE_TYPES } from 'shared/constants';
import { KnowledgeDocumentDownload } from './knowledge-content-preview/knowledge-document-download.component';
import { useContentPreview } from 'src/pages/application/knowledge-content/hooks';

const KnowledgeContentComponent = React.memo(function KnowledgeContentComponent({
  type,
  close,
  edit,
  id,
  ...props
}) {
  const [editMode, setEditMode] = useState(edit);
  const lang = useSelector(getLang('KNOWLEDGE_EDITOR'));
  const langTagsForm = useSelector(getLang('TAGS_FORM'));
  const langTags = useSelector(getLang('TAGS'));
  const langRevision = useSelector(getLang('REVISION_LOG'));
  const [knowledgeId, setKnowledgeId] = useState(id);
  const knowledge = useSelector(state => getKnowledgeItemById(state, knowledgeId));
  const dispatch = useDispatch();

  // used by retro OCR conversion
  const [knowledgeEntityForEditor, setKnowledgeEntityForEditor] = useState(null);

  const { isHub, setItemId } = useContentPreview();

  useEffect(() => {
    if (
      isHub ||
      type !== KNOWLEDGE_TYPES.CONTENT ||
      knowledge?.content ||
      editMode ||
      !knowledgeId
    ) {
      return;
    }

    dispatch(fetchKnowledgeItemContent({ id: knowledgeId }));
  }, [knowledgeId, type, knowledge?.content, editMode, dispatch, isHub]);

  useEffect(() => {
    if (isHub) {
      setItemId(id);
    }
  }, [id, isHub, setItemId]);

  const toggleEditMode = useCallback(() => {
    setEditMode(!editMode);
  }, [setEditMode, editMode]);

  const closeEditMode = useCallback(
    id => {
      if (!id) {
        close();
        return;
      }

      setKnowledgeId(id);
      setEditMode(false);
      setKnowledgeEntityForEditor(null);
    },
    [setEditMode, setKnowledgeId, close],
  );

  if (isHub && knowledgeEntityForEditor) {
    return (
      <KnowledgeContentEditor
        knowledge={{
          ...knowledgeEntityForEditor,
        }}
        onFinish={closeEditMode}
        {...props}
      />
    );
  }

  if (editMode || knowledgeEntityForEditor) {
    const subtitle =
      knowledge?.subtitle || knowledgeEntityForEditor?.subtitle || props?.subtitle || '';
    return (
      <KnowledgeContentEditor
        knowledge={{
          type,
          id: knowledgeId,
          ...knowledge,
          ...knowledgeEntityForEditor,
          ...props,
          subtitle,
        }}
        onFinish={closeEditMode}
        {...props}
      />
    );
  }

  if (type === KNOWLEDGE_TYPES.DOCUMENT) {
    return id ? (
      <KnowledgeDocumentDownload
        close={close}
        knowledge={knowledge}
        toggleEditMode={toggleEditMode}
        lang={lang}
        {...props}
      />
    ) : null;
  }

  return (
    <KnowledgeContentPreviewComponent
      knowledge={knowledge}
      lang={lang}
      langTags={langTags}
      langTagsForm={langTagsForm}
      langRevision={langRevision}
      id={knowledgeId}
      close={close}
      toggleEditMode={toggleEditMode}
      setKnowledgeEntityForEditor={setKnowledgeEntityForEditor}
      {...props}
    />
  );
});

KnowledgeContentComponent.defaultProps = {
  close: () => {},
  edit: false,
};

export { KnowledgeContentComponent };
