import API from './API';
import { DELETE, GET, POST, PUT } from '../utils/Executer';
import { ADMISSION_SERVICE_URL as servicePath } from '../constants/APIconstants';

class AdmissionServiceAPI {
  async getAll() {
    return await API.client.execute(GET, servicePath);
  }

  async getAdmissionById({ id }) {
    return await API.client.execute(GET, `${servicePath}${id}`);
  }

  // admission category
  async updateCategory({ id, ...change }) {
    return await API.client.execute(PUT, `${servicePath}${id}`, change);
  }

  async deleteCategory({ id }) {
    return await API.client.execute(DELETE, `${servicePath}${id}`);
  }

  async updateCategorySubcategories({ id, categoryId, index }) {
    return await API.client.execute(POST, `${servicePath}${id}/subcategories/order`, {
      categoryId,
      index,
    });
  }

  async createCategory({ data, workspaceId }) {
    return await API.client.execute(POST, `${servicePath}?workspace=${workspaceId}`, data);
  }

  // Create value
  async createCheck({ categoryId, ...data }) {
    return await API.client.execute(POST, `${servicePath}${categoryId}/check`, data);
  }

  async moveAdmissionCategory({ id, categoryId, sourceId }) {
    return await API.client.execute(PUT, `${servicePath}${id}/move`, {
      categoryId,
      sourceId,
    });
  }

  async updateCheck({ categoryId, checkId, ...data }) {
    return await API.client.execute(PUT, `${servicePath}${categoryId}/check/${checkId}`, data);
  }

  async deleteCheck({ categoryId, id }) {
    return await API.client.execute(DELETE, `${servicePath}${categoryId}/check/${id}`);
  }

  async deleteLabItem({ id }) {
    return await API.client.execute(DELETE, `${servicePath}values/${id}`);
  }

  async updateChecksOrder({ id, itemId, index }) {
    return await API.client.execute(POST, `${servicePath}${id}/checks/order`, {
      itemId,
      index,
    });
  }

  async moveChecklist({ id, categoryId, sourceId }) {
    return await API.client.execute(POST, `${servicePath}${sourceId}/check/${id}/move`, {
      categoryId,
    });
  }

  async generateReport(data) {
    return await API.client.execute(POST, `${servicePath}generate-report`, data);
  }
}

export default new AdmissionServiceAPI();
