import React from 'react';
import styles from './lab-binder.module.scss';
import classnames from 'classnames';
import { useLocation } from 'react-router';
import { Cross } from '../../../images';
import { Button, Tooltip } from '../../../components/core';
import update from 'immutability-helper';
import { useDrop } from 'react-dnd';
import { LabValueEditor } from '../lab/components/lab-value/lab-value-editor';
import { LabValue } from '../lab/components/lab-value';
import { LabValueDelete } from '../lab/components/lab-value/lab-value-delete';
import { TagsIcons } from '../../../components/tags/tagsIcons';
import {
  Lang,
  INTERNAL_ROUTING,
  ROUTES,
  CATEGORY_TYPES,
  EVENTS,
  ENTITY_TYPES,
  REQUEST_ACCESS_ENTITIES,
} from 'shared/constants';
import { TextTag } from '../../../components/tags/text-tags';
import { ShareBoxComponent } from '../../../components/share';
import analyticsService from 'src/helpers/analytics.service';
import { useSelector } from 'react-redux';
import { getUser } from 'shared/store/selectors/user.selector';
import { isCurrentUserValidOwner } from 'shared/store/selectors/owners.selector';
import labServiceApi from 'shared/api/lab.service.api';
import { assertPermission } from 'src/components/common/access-restricted/assert-permission';

const LabBinderPageComponent = React.memo(function LabBinderPageComponent({
  id,
  category,
  items,
  labSelectValues,
  initialOpenState,
  adminMode,
  updateValueOrder,
  createQuickLabItem,
  lang,
  langTooltip,
}) {
  const { title, ownerIds, tags, drugClass } = category;
  const { search } = useLocation();

  const [isOpen, setOpenState] = React.useState(initialOpenState);
  const [data, setData] = React.useState(items);

  const [deleteLabValue, setDeleteLabValue] = React.useState(null);
  const [labValueEntity, setLabValueEntity] = React.useState(null);

  React.useEffect(() => {
    if (id) {
      assertPermission({
        serviceMethod: async () => await labServiceApi.getCategoryById({ id }),
        entity: REQUEST_ACCESS_ENTITIES.LAB_CATEGORY,
        id,
      });
    }
  }, [id]);

  const EVENT_DATA = {
    id,
    title,
    drugClass,
    ...tags,
  };

  React.useEffect(() => {
    if (!id) return;

    analyticsService.track(EVENTS.CONTENT.OPEN, {
      ...EVENT_DATA,
      entityType: ENTITY_TYPES.compendium,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Drag and Drop
  const dndGroup = `values`;
  const [, drop] = useDrop({ accept: dndGroup });

  const dragItem = (id, atIndex) => {
    const { item, index } = findItem(id);
    setData(
      update(data, {
        $splice: [
          [index, 1],
          [atIndex, 0, item],
        ],
      }),
    );
  };

  const findItem = id => {
    const item = data.find(i => `${i.id}` === id);
    return {
      item,
      index: data.indexOf(item),
    };
  };

  const finishDrag = itemId => {
    updateValueOrder({
      id,
      itemId,
      index: findItem(itemId).index,
    });
  };

  // Delete value items
  const deleteValueAction = React.useCallback(({ id, title }) => {
    // TODO: analytics
    setDeleteLabValue({ id, title });
  }, []);

  const onFinishDelete = React.useCallback(() => {
    setDeleteLabValue(null);
  }, []);

  // Editor
  const onFinishEditor = React.useCallback(() => {
    setLabValueEntity(null);
  }, []);

  const editOrCreateLabValue = React.useCallback(({ item = {} }) => {
    setLabValueEntity({ ...item });
  }, []);

  const createNewLabValue = React.useCallback(
    ({ item = {} }) => {
      setLabValueEntity({ ownerIds, tags, drugClass, ...item });
    },
    [ownerIds, tags, drugClass],
  );

  const currentUser = useSelector(getUser);
  const isUserValidOwner = useSelector(isCurrentUserValidOwner);

  const createQuickValue = React.useCallback(async () => {
    await createQuickLabItem({
      categoryId: category.id,
      ownerIds: isUserValidOwner ? [currentUser.id] : ownerIds,
      tags,
      drugClass,
    });
  }, [createQuickLabItem, category, tags, drugClass, currentUser, ownerIds, isUserValidOwner]);

  const close = React.useCallback(() => {
    setOpenState(false);
    labSelectValues(null);
  }, [labSelectValues]);

  React.useEffect(() => {
    if (id) {
      setOpenState(true);
      setData(items);
    } else {
      setOpenState(false);
    }
  }, [id, category, items]);

  React.useEffect(() => {
    const searchId = new URLSearchParams(search).get(INTERNAL_ROUTING.QUERY_PARAMS.COMPENDIUM);

    if (!searchId && isOpen) {
      setOpenState(false);
    }
  }, [search, isOpen]);

  const renderTags = () => {
    if (!tags?.contentType && !tags?.specialty) return null;

    return (
      <div className={styles.tagsContainer}>
        <TagsIcons tags={tags} hideTags={{ targetAudience: true, contentType: true }} large />
        <TextTag tag={tags?.contentType} large />
      </div>
    );
  };

  const renderList = () => {
    return data?.map(item => (
      <LabValue
        key={item.id}
        item={item}
        deleteValue={deleteValueAction}
        editValue={editOrCreateLabValue}
        adminMode={adminMode}
        {...{
          dragItem,
          findItem,
          dndGroup,
          finishDrag,
        }}
      />
    ));
  };

  return (
    <>
      <div className={classnames(styles.root, isOpen ? styles.open : undefined)}>
        <Tooltip title={langTooltip.CLOSE} placement={'bottom'}>
          <div onClick={close} className={styles.close}>
            <img src={Cross} alt="Close" />
          </div>
        </Tooltip>
        <div className={styles.tagIconsWrapper}>
          <div className={styles.header}>
            {title && <h2>{title}</h2>}
            <ShareBoxComponent
              path={`${ROUTES.LABS}?${INTERNAL_ROUTING.QUERY_PARAMS.COMPENDIUM}=${id}`}
              id={id}
              title={title}
              tags={tags}
              entityType={CATEGORY_TYPES.VALUES}
            />
          </div>
          {renderTags()}
        </div>
        {adminMode && (
          <div className={styles.buttons}>
            <Button
              size="small"
              onClick={createNewLabValue}
              buttonStyle="primary"
              className={styles.buttonWithMargin}
            >
              {lang.ADD}
            </Button>

            <Button
              size="small"
              onClick={createQuickValue}
              buttonStyle="primary"
              className={styles.buttonWithMargin}
            >
              {lang.QUICK_ADD}
            </Button>
          </div>
        )}

        <div ref={drop}>{renderList()}</div>
      </div>

      {deleteLabValue && <LabValueDelete entity={deleteLabValue} onFinish={onFinishDelete} />}

      {labValueEntity && (
        <LabValueEditor categoryId={id} value={labValueEntity} onFinish={onFinishEditor} />
      )}
    </>
  );
});

LabBinderPageComponent.defaultProps = {
  items: [],
  category: {
    title: '',
  },
  labSelectValues: () => {},
  initialOpenState: false,
  adminMode: false,
  updateValueOrder: () => {},
  createQuickLabItem: () => {},
  lang: Lang.COMPENDIUM_BUTTONS,
  langTooltip: Lang.ACTION_BUTTON_TOOLTIPS,
};

export { LabBinderPageComponent };
