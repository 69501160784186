import React, { useEffect, useState } from 'react';
import styles from './access-restricted.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import classNames from 'classnames';
import { Button } from 'src/components/design-system';
import { useHistory } from 'react-router';
import { EVENTS, INTERNAL_ROUTING, ROUTES } from 'shared/constants';
import communicationServiceApi from 'shared/api/communication.service.api';
import { useLocation } from 'react-router-dom-v5-compat';
import analyticsService from 'src/helpers/analytics.service';

const AccessRestricted = () => {
  const lang = useSelector(getLang('ACCESS_RESTRICTED'));
  const history = useHistory();
  const [requestSent, setRequestSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const entity = searchParams.get(INTERNAL_ROUTING.QUERY_PARAMS.RESTRICTED_ENTITY);
  const itemId = searchParams.get(INTERNAL_ROUTING.QUERY_PARAMS.RESTRICTED_ID);

  useEffect(() => {
    analyticsService.track(EVENTS.WORKSPACE.OPEN_RESTRICTED_PAGE, {
      entity,
      itemId,
    });
  }, [itemId, entity]);

  const askForAccess = async () => {
    setLoading(true);

    const res = await communicationServiceApi.requestAccess({
      data: {
        entity,
        itemId,
        timestamp: new Date().toISOString(),
      },
    });

    if (res?.error) {
      setLoading(false);
      setError(res.error);

      analyticsService.track(EVENTS.WORKSPACE.SEND_ACCESS_REQUEST_FAILED, {
        entity,
        itemId,
      });

      return;
    }

    analyticsService.track(EVENTS.WORKSPACE.SEND_ACCESS_REQUEST, {
      entity,
      itemId,
    });

    setError(null);
    setRequestSent(true);
    setLoading(false);
  };

  const redirectToHome = () => {
    history.push(ROUTES.HOME);
  };

  return (
    <div className={classNames('new-design-system', styles.root)}>
      <h1>{requestSent ? lang.REQUEST_SENT_TITLE : lang.TITLE}</h1>
      {requestSent ? (
        <p>{lang.REQUEST_SENT_MESSAGE}</p>
      ) : (
        <Button type="primary" onClick={askForAccess} loading={loading}>
          {lang.REQUEST_ACCESS}
        </Button>
      )}
      {error && <p>{lang.ERROR}</p>}
      <div className={styles.back}>
        <Button type="link" onClick={redirectToHome}>
          {lang.BACK_TO_HOME}
        </Button>
      </div>
    </div>
  );
};

export { AccessRestricted };
