import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { camelCaseToWords, sortByAlphabet } from 'shared/utils';
import {
  ALLOWED_PERMISSIONS_FOR_CHANGE,
  DIVISION,
  PROFESSIONS,
  ROLES_ALL,
  SPECIALTY,
  TAGS_SPECIALTY,
  TRAINING_LEVELS_ALL,
} from 'shared/constants';
import { useRotationOptions } from 'shared/hooks/profile.context';
import { useProfileLang } from 'shared/hooks/profile.hooks';

const COLUMN_WIDTH = 150;

export const ENTITY = {
  USER: 'user',
  CONTACT: 'contact',
};

export const useColumns = ({ entity = ENTITY.USER, renderActions = () => {} }) => {
  const lang = useSelector(getLang('USERS_MANAGEMENT'));
  const { rotations } = useRotationOptions();

  const {
    userProfileOptions,
    locationsOptions,
    workspacesOptions,
    getValueFromLang,
    getMultipleValuesFromLang,
    getLocationsValues,
    getWorkspaceValues,
    getWorkspaceName,
    getWorkspaceId,
  } = useProfileLang();

  const onFilter = useCallback((value, record, field) => record?.[field]?.includes(value), []);

  const createColumnsFilters = useCallback(
    constants =>
      Object.values(constants)
        .map(key => ({
          text: userProfileOptions[key] ?? key,
          value: key,
        }))
        .sort((a, b) => sortByAlphabet(a.text, b.text)),
    [userProfileOptions],
  );

  const commonColumns = useMemo(
    () => [
      {
        title: lang.EMAIL,
        dataIndex: 'email',
        key: 'email',
        sorter: {
          compare: (a, b) => sortByAlphabet(a.email, b.email),
        },
        render: value => value ?? '-',
        width: 275,
      },
      {
        title: lang.PHONE,
        dataIndex: 'phone',
        key: 'phone',
        sorter: {
          compare: (a, b) => sortByAlphabet(a.phone, b.phone),
        },
        render: value => value ?? '-',
        width: COLUMN_WIDTH,
      },
      {
        title: lang.PAGER,
        dataIndex: 'pager',
        key: 'pager',
        sorter: {
          compare: (a, b) => sortByAlphabet(a.pager, b.pager),
        },
        render: value => value ?? '-',
        width: COLUMN_WIDTH,
      },
      {
        title: lang.CISCO,
        dataIndex: 'cisco',
        key: 'cisco',
        sorter: {
          compare: (a, b) => sortByAlphabet(a.cisco, b.cisco),
        },
        render: value => value ?? '-',
        width: COLUMN_WIDTH,
      },
      {
        title: lang.DEPARTMENT,
        dataIndex: 'department',
        key: 'department',
        ellipsis: true,
        sorter: {
          compare: (a, b) => sortByAlphabet(a.department, b.department),
        },
        render: getValueFromLang,
        width: COLUMN_WIDTH,
        onFilter: (value, record) => onFilter(value, record, 'department'),
        filters: createColumnsFilters(TAGS_SPECIALTY),
        filterSearch: true,
      },
      {
        title: lang.PROFESSION,
        dataIndex: 'profession',
        key: 'profession',
        ellipsis: true,
        sorter: {
          compare: (a, b) => sortByAlphabet(a.profession, b.profession),
        },
        render: getValueFromLang,
        width: COLUMN_WIDTH,
        onFilter: (value, record) => onFilter(value, record, 'profession'),
        filters: createColumnsFilters(PROFESSIONS),
        filterSearch: true,
      },
      {
        title: lang.ROLE,
        dataIndex: 'role',
        key: 'role',
        ellipsis: true,
        sorter: {
          compare: (a, b) => sortByAlphabet(a.role, b.role),
        },
        render: getValueFromLang,
        width: COLUMN_WIDTH,
        onFilter: (value, record) => onFilter(value, record, 'role'),
        filters: createColumnsFilters(ROLES_ALL),
        filterSearch: true,
      },
      {
        title: lang.LEVEL_OF_TRAINING,
        dataIndex: 'levelOfTraining',
        key: 'levelOfTraining',
        ellipsis: true,
        sorter: {
          compare: (a, b) => sortByAlphabet(a.levelOfTraining, b.levelOfTraining),
        },
        render: getValueFromLang,
        width: COLUMN_WIDTH,
        onFilter: (value, record) => onFilter(value, record, 'levelOfTraining'),
        filters: createColumnsFilters(TRAINING_LEVELS_ALL),
        filterSearch: true,
      },
      {
        title: lang.ROTATION,
        dataIndex: 'rotation',
        key: 'rotation',
        ellipsis: true,
        sorter: {
          compare: (a, b) => sortByAlphabet(a.rotation, b.rotation),
        },
        render: value => userProfileOptions[value] ?? value ?? '-',
        width: COLUMN_WIDTH,
        onFilter: (value, record) => onFilter(value, record, 'rotation'),
        filters: createColumnsFilters(rotations),
        filterSearch: true,
      },
      {
        title: lang.DIVISION,
        dataIndex: 'division',
        key: 'division',
        ellipsis: true,
        render: getMultipleValuesFromLang,
        width: COLUMN_WIDTH,
        onFilter: (value, record) => onFilter(value, record, 'division'),
        filters: createColumnsFilters(DIVISION),
        filterSearch: true,
      },
      locationsOptions.length > 0
        ? {
            title: lang.LOCATION,
            dataIndex: 'location',
            key: 'location',
            ellipsis: true,
            render: getLocationsValues,
            width: COLUMN_WIDTH,
            onFilter: (value, record) =>
              onFilter(
                locationsOptions.find(({ name }) => name === value)?.code,
                record,
                'location',
              ),
            filters: createColumnsFilters(locationsOptions.map(({ name }) => name)),
            filterSearch: true,
          }
        : {},
      {
        title: lang.SPECIALTY,
        dataIndex: 'specialty',
        key: 'specialty',
        ellipsis: true,
        render: getMultipleValuesFromLang,
        width: COLUMN_WIDTH,
        onFilter: (value, record) => onFilter(value, record, 'specialty'),
        filters: createColumnsFilters(SPECIALTY),
        filterSearch: true,
      },
      {
        title: lang.TITLE,
        dataIndex: 'title',
        key: 'title',
        sorter: {
          compare: (a, b) => sortByAlphabet(a.title, b.title),
        },
        render: value => value ?? '-',
        width: COLUMN_WIDTH,
      },
    ],
    [
      lang,
      userProfileOptions,
      createColumnsFilters,
      getValueFromLang,
      onFilter,
      rotations,
      locationsOptions,
      getMultipleValuesFromLang,
      getLocationsValues,
    ],
  );

  const nameColumn = useMemo(
    () => [
      {
        title: lang.NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: {
          compare: (a, b) => sortByAlphabet(a.name, b.name),
        },
        render: value => value ?? '-',
        defaultSortOrder: 'ascend',
        fixed: 'left',
        width: COLUMN_WIDTH,
      },
    ],
    [lang],
  );

  const permissionsOptions = useMemo(
    () =>
      ALLOWED_PERMISSIONS_FOR_CHANGE.reduce(
        (acc, value) => ({ ...acc, [value]: camelCaseToWords(value) }),
        {},
      ),
    [],
  );

  const userColumns = useMemo(
    () => [
      ...nameColumn,
      {
        title: lang.PERMISSIONS,
        dataIndex: 'permissions',
        key: 'permissions',
        sorter: {
          compare: (a, b) => sortByAlphabet(a.permissions, b.permissions),
        },
        render: value => camelCaseToWords(value),
        width: COLUMN_WIDTH,
        onFilter: (value, record) => onFilter(value, record, 'permissions'),
        filters: Object.keys(permissionsOptions)
          .map(key => ({ value: key, text: permissionsOptions[key] }))
          .sort((a, b) => sortByAlphabet(a.text, b.text)),
      },
      ...commonColumns,
    ],
    [lang, commonColumns, nameColumn, onFilter, permissionsOptions],
  );

  const contactColumns = useMemo(
    () => [
      ...nameColumn,
      ...commonColumns,
      workspacesOptions.length > 1
        ? {
            title: lang.WORKSPACE,
            dataIndex: 'workspaceId',
            key: 'workspaceId',
            sorter: {
              compare: (a, b) =>
                sortByAlphabet(getWorkspaceName(a.workspaceId), getWorkspaceName(b.workspaceId)),
            },
            render: getWorkspaceValues,
            width: COLUMN_WIDTH,
            onFilter: (value, record) => onFilter(getWorkspaceId(value), record, 'workspaceId'),
            filters: createColumnsFilters(workspacesOptions.map(({ name }) => name)),
            filterSearch: true,
          }
        : {},
      {
        title: lang.ACTIONS,
        dataIndex: 'actions',
        key: 'actions',
        render: (_, record) => renderActions(record),
        width: 100,
        fixed: 'right',
      },
    ],
    [
      lang,
      commonColumns,
      nameColumn,
      workspacesOptions,
      getWorkspaceName,
      getWorkspaceId,
      onFilter,
      createColumnsFilters,
      renderActions,
      getWorkspaceValues,
    ],
  );

  if (entity === ENTITY.USER) {
    return userColumns;
  }

  if (entity === ENTITY.CONTACT) {
    return contactColumns;
  }
  return null;
};
