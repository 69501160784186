import { connect } from 'react-redux';
import {
  createUsers,
  updateUser,
  manageRestrictedWorkspacesAccess,
} from 'shared/store/actions/users-management.actions';
import { UserDetailsFormComponent } from './user-details-form.component';
import { isAccountAdmin } from 'shared/store/selectors/auth.selector';

const mapStateToProps = ({ lang, auth }) => {
  return {
    lang: lang.USER_MANAGEMENT,
    isAccountAdmin: isAccountAdmin({
      auth,
    }),
  };
};

export const UserDetailsForm = connect(mapStateToProps, {
  createUsers,
  updateUser,
  manageRestrictedWorkspacesAccess,
})(UserDetailsFormComponent);
