import React from 'react';
import { AUTHENTICATION_TYPE, DATA_KEYS, ROUTES } from 'shared/constants';
import { AuthPage } from '../auth-page-wrapper';
import { useFormik } from 'formik';
import styles from './sign-in.module.scss';
import { loginSchema } from 'shared/schemas/user.schemas';
import UserService from 'shared/services/user.service';
import { TextInput } from 'src/components/design-system/forms';
import { Button } from 'src/components/design-system/buttons';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { NavLink, useLocation } from 'react-router-dom-v5-compat';
import { useOauthConfig } from '../hooks';
import { redirectToLoginWithSSO } from 'src/helpers/sso';
import { GENERIC_SERVER_ERROR_MESSAGE } from 'shared/constants/applicationErrorCodes';

const { WELCOME_SCREEN } = DATA_KEYS;

export function SignInPage() {
  const [error, setError] = React.useState(null);
  const [loading, setLoadingState] = React.useState(false);
  const { state } = useLocation();
  const { doSignInWithOauth, getConfigByProvider, generateAuthenticationUrl } = useOauthConfig();

  React.useEffect(() => {
    async function signInWithOauth() {
      if (state?.query?.code) {
        setError('');
        setLoadingState(true);

        const res = await doSignInWithOauth(state);

        if (res.error) {
          setError(res.error);
        }

        setLoadingState(false);
      }
    }
    signInWithOauth();
  }, [state, doSignInWithOauth]);

  const lang = useSelector(getLang('WELCOME_SCREEN'));

  const signInWithOauth = React.useCallback(
    config => {
      window.location.href = generateAuthenticationUrl(config);
    },
    [generateAuthenticationUrl],
  );

  const signInAction = React.useCallback(
    async ({ email }) => {
      setError('');
      setLoadingState(true);

      let res = await UserService.signin({ email });

      if (res?.error) {
        setError(
          res.error === GENERIC_SERVER_ERROR_MESSAGE ? res.error : lang.FAILED_TO_AUTHENTICATION,
        );
      } else if (res?.authType === AUTHENTICATION_TYPE.SAML) {
        const { authenticationMethodId, organizationId } = res.payload || {};

        if (!authenticationMethodId || !organizationId) {
          setError(lang.SSO_ERROR_MESSAGE);
        } else {
          redirectToLoginWithSSO({ authenticationMethodId, organizationId });
        }
      } else if (res?.authType === AUTHENTICATION_TYPE.OAUTH) {
        const { provider } = res.payload;
        const config = getConfigByProvider(provider);

        if (!config) {
          setError(lang.FAILED_TO_AUTHENTICATION);
        } else {
          signInWithOauth(config);
        }
      }

      setLoadingState(false);
    },
    [lang.FAILED_TO_AUTHENTICATION, lang.SSO_ERROR_MESSAGE, signInWithOauth, getConfigByProvider],
  );

  const { handleChange, handleBlur, handleSubmit, values, errors, touched, isValid } = useFormik({
    validationSchema: loginSchema,
    initialValues: { email: '' },
    onSubmit: signInAction,
  });

  return (
    <AuthPage title={lang.HEADER} content={lang.CONTENT}>
      <form onSubmit={handleSubmit}>
        <TextInput
          data-key={WELCOME_SCREEN.EMAIL_INPUT}
          type="email"
          name="email"
          value={values.email}
          error={touched.email && errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus={true}
          size="middle"
          placeholder={lang.EMAIL_PLACEHOLDER}
          disabled={loading}
          status={error ? 'error' : 'default'}
        />

        <span className={styles.error}>{error}</span>

        <Button
          data-key={WELCOME_SCREEN.SUBMIT_BUTTON}
          disabled={!isValid}
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          {lang.SIGN_IN}
        </Button>

        <p className={styles.notMember}>
          <NavLink to={ROUTES.NOT_ALLOWED} className={styles.link}>
            {lang.NOT_A_MEMBER_CONTACT_US}
          </NavLink>
          {lang.NOT_A_MEMBER}
        </p>
      </form>
    </AuthPage>
  );
}
