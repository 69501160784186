import React, { useCallback, useState } from 'react';
import styles from './user-profile.module.scss';
import { UserProfile } from '../../../components/user-profile';
import { useSelector } from 'react-redux';
import { needToUpdateProfile } from 'shared/store/selectors/user.selector';
import { Modal } from 'antd';

const UserProfilePopup = () => {
  const shouldShowPopup = useSelector(({ auth, generalConfig }) =>
    needToUpdateProfile({ auth, generalConfig }),
  );
  const [showPopup, setShowPopup] = useState(shouldShowPopup);

  const onClose = useCallback(() => {
    setShowPopup(false);
  }, []);

  return (
    <Modal
      width="100%"
      className={styles.popup}
      open={showPopup}
      centered
      maskClosable={false}
      closable={false}
      footer={null}
      keyboard={false}
    >
      <UserProfile isPopup onClose={onClose} />
    </Modal>
  );
};

export { UserProfilePopup };
