import { connect } from 'react-redux';
import { moveKnowledgeCategory } from 'shared/store/actions/knowledge.categories.actions';
import {
  generateBreadcrumbs,
  getCategoriesById,
  getCategoryById,
  getRoot,
  isSection,
} from 'shared/store/selectors/knowledge.selector';
import { MovePopupComponent } from '../../../../../../components/move/move-popup.component';
import { STORE_ENTITY_TYPES } from 'shared/constants';

const mapStateToProps = ({ lang, knowledge }, { id }) => {
  const category = getCategoryById(knowledge, { id });

  return {
    lang: lang.KNOWLEDGE_MOVE,
    item: category,
    getRoot,
    getCategoriesById,
    getCategoryById,
    generateBreadcrumbs,
    isSection,
    isCategoryMove: true,
    disabledIds: category.subcategories.concat(category.id),
    parentId: category.path.split(',').pop(),
    entity: STORE_ENTITY_TYPES.KNOWLEDGE,
  };
};

export const KnowledgeCategoryMovePopup = connect(mapStateToProps, {
  doMove: moveKnowledgeCategory,
})(MovePopupComponent);
