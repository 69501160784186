import { CATEGORY_TYPES, SEARCH_TYPES_KEYS } from '../constants';
import { useExpiryFilterWithAdmin } from './knowledge.hooks';

/**
 * Filter out section items
 * @param {*} item
 * @returns boolean
 */

export const useSearchResultsPreparation = (
  filters,
  searchType = SEARCH_TYPES_KEYS.none,
  adminMode = false,
  isContactsEnabled,
) => {
  const expiryFilter = useExpiryFilterWithAdmin({ adminMode });
  const { filtersByTags = () => true } = filters;

  const filterKnowledgeByType = thumbnail => {
    if (searchType === SEARCH_TYPES_KEYS.none) return false;
    if (![SEARCH_TYPES_KEYS.documents, SEARCH_TYPES_KEYS.media].includes(searchType)) return true;
    return searchType === SEARCH_TYPES_KEYS.documents ? !!thumbnail : thumbnail === '';
  };

  const filterBySearchType = typeList => typeList.includes(searchType);

  const mapLinks = link => {
    const { item, type } = link || {};
    const { links, ...rest } = item || {};
    if (!links?.length) return [link];
    return links.map(link => ({ item: { ...rest, ...link }, type }));
  };

  const filterDuplicateLinksInWorkspace = (link, index, links) => {
    const { item } = link || {};
    const { id, workspaceId } = item || {};
    return (
      links.findIndex(({ item }) => item.id === id && item.workspaceId === workspaceId) === index
    );
  };

  const prepareResults = (results, withCategories) => {
    if (!results) return {};

    const filterAndSlice = (items, filterFunc, sliceCount = 50) =>
      items.filter(filterFunc).filter(filtersByTags).slice(0, sliceCount);

    const excludeRootPaths = category => {
      const { item } = category;
      return item.path !== 'root' && item.path !== 'ROOT';
    };

    const knowledgeItems = results?.knowledge
      ?.filter(
        ({ item }) =>
          expiryFilter(item) && !filterKnowledgeByType(item.thumbnail) && filtersByTags(item),
      )
      .flatMap(mapLinks)
      .filter(filterDuplicateLinksInWorkspace)
      .slice(0, 200);

    const labItems = filterAndSlice(results.lab, () =>
      filterBySearchType([SEARCH_TYPES_KEYS.none, SEARCH_TYPES_KEYS.compendium]),
    );

    const admissionItems = filterAndSlice(
      results.admission,
      ({ type }) =>
        filterBySearchType([SEARCH_TYPES_KEYS.none, SEARCH_TYPES_KEYS.admission]) &&
        type === CATEGORY_TYPES.CHECKS,
    );

    const knowledgeCategories = withCategories
      ? filterAndSlice(
          results?.knowledgeCategories,
          category =>
            excludeRootPaths(category) &&
            filterBySearchType([SEARCH_TYPES_KEYS.none, SEARCH_TYPES_KEYS.category]),
        )
      : [];

    const labCategories = withCategories
      ? filterAndSlice(
          results?.labCategories,
          category =>
            excludeRootPaths(category) &&
            filterBySearchType([SEARCH_TYPES_KEYS.none, SEARCH_TYPES_KEYS.category]),
        )
      : [];

    const admissionCategories = withCategories
      ? filterAndSlice(
          results?.admissionCategories,
          category =>
            excludeRootPaths(category) &&
            filterBySearchType([SEARCH_TYPES_KEYS.none, SEARCH_TYPES_KEYS.category]),
        )
      : [];

    const contactItems = isContactsEnabled
      ? results.contacts
          ?.filter(() => filterBySearchType([SEARCH_TYPES_KEYS.none, SEARCH_TYPES_KEYS.contacts]))
          .slice(0, 50)
          .sort(({ item: a }, { item: b }) => a.firstName.localeCompare(b.firstName))
      : [];

    const resultsCount = [
      knowledgeItems.length,
      labItems.length,
      admissionItems.length,
      knowledgeCategories.length,
      labCategories.length,
      admissionCategories.length,
      contactItems.length,
    ].reduce((acc, curr) => acc + curr, 0);

    return {
      knowledgeItems,
      labItems,
      admissionItems,
      knowledgeCategories,
      labCategories,
      admissionCategories,
      contactItems,
      resultsCount,
    };
  };

  return { prepareResults };
};
