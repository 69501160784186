import { EVENTS } from './analytics';

export const ENTITY_TYPES = {
  knowledge: 'knowledge',
  contacts: 'contacts',
  compendium: 'compendium',
  admission: 'checklist',
};

export const STORE_ENTITY_TYPES = {
  KNOWLEDGE: 'knowledge',
  LAB: 'lab',
  ADMISSION: 'admission',
};

export const SEARCH_TYPES_KEYS = {
  none: null,
  documents: 'documents',
  media: 'media',
  contacts: 'contacts',
  compendium: 'compendium',
  admission: 'checklist',
  category: 'category',
};

export const SEARCH_TYPES = lang => [
  {
    label: lang.ALL_ITEMS,
    type: SEARCH_TYPES_KEYS.none,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_ALL,
  },
  {
    label: lang.DOCUMENTS,
    type: SEARCH_TYPES_KEYS.documents,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_DOCUMENTS,
  },
  {
    label: lang.MEDIA,
    type: SEARCH_TYPES_KEYS.media,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_MEDIA,
  },
];

export const KNOWLEDGE_RESOURCES_TYPES = {
  KNOWLEDGE_CATEGORY: 'knowledge-category',
  KNOWLEDGE_ITEM: 'knowledge-item',
  LAB: 'lab',
  CHECKLIST: 'checklist',
  EXTERNAL_URL: 'external-url',
};

export const buildSearchTypesFromKeys = (lang, keys) =>
  keys.map(key => SEARCH_PAGE_TYPES(lang).find(type => type.type === key));

export const SEARCH_PAGE_TYPES = lang => [
  {
    label: lang.ALL_ITEMS,
    type: SEARCH_TYPES_KEYS.none,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_ALL,
  },
  {
    label: lang.DOCUMENTS,
    type: SEARCH_TYPES_KEYS.documents,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_DOCUMENTS,
  },
  {
    label: lang.MEDIA,
    type: SEARCH_TYPES_KEYS.media,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_MEDIA,
  },
  {
    label: lang.CONTACTS,
    type: SEARCH_TYPES_KEYS.contacts,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_CONTACTS,
  },
  {
    label: lang.COMPENDIUM,
    type: SEARCH_TYPES_KEYS.compendium,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_COMPENDIUM,
  },
  {
    label: lang.CHECKLIST,
    type: SEARCH_TYPES_KEYS.admission,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_CHECKLIST,
  },
];

export const CATEGORY_TYPES = {
  CATEGORY: 'category',
  KNOWLEDGE: 'knowledge',
  VALUES: 'values',
  CHECKS: 'checklist',
  SECTION: 'section',
};

export const KNOWLEDGE_ITEM = 'knowledge item';
export const KNOWLEDGE_ITEM_PREVIEW = 'knowledge item preview';

export const DASHBOARD_PARAMS = {
  page: 1,
  pageSize: 5,
  recentlyCreatedSort: 'createdAt',
  recentlyUpdatedSort: 'itemUpdatedAt',
  direction: 'desc',
};

export const KNOWLEDGE_PAGE_PARAMS = {
  pageSize: 300,
  page: 1,
};

export const SORT_BY_THUMBNAIL = ({ thumbnail: x }, { thumbnail: y }) =>
  !!x === !!y ? 0 : !!x ? 1 : -1;

export const KNOWLEDGE_TYPES = {
  CONTENT: 'content',
  LINK: 'link',
  EXTERNAL_LINK: 'external-link',
  DOCUMENT: 'document',
  PDF: 'pdf',
  IMAGE: 'image',
  VIDEO: 'video',
  OTHER: 'link',
};

export const ALLOW_IFRAME_DOMAINS = ['headtotoe.io', 'c8.health', 'c8health.com', 'mdcalc.com'];
export const INTERNAL_DOMAINS = ['headtotoe.io', 'c8.health', 'c8health.com'];

export const REVISION_LOG_ADVISORS = {
  emailDomain: /@((c8\.health)|(headtotoe\.io)|(c8health\.com))/,
  name: 'C8health Advisor',
  supportEmail: 'support@c8health.com ',
};

export const SUGGEST_CONTENT_SOURCES = {
  URL: 'URL',
  UPLOAD_FILE: 'UPLOAD_FILE',
  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  TAKE_PICTURE: 'TAKE_PICTURE',
  PASTE_CONTENT: 'PASTE_CONTENT',
};

export const KNOWLEDGE_ITEM_ACTIONS = {
  SHARE: 'SHARE',
  ITEM_INFO: 'ITEM_INFO',
  DOWNLOAD_TO_DEVICE: 'DOWNLOAD_TO_DEVICE',
  REMOVE_FROM_DEVICE: 'REMOVE_FROM_DEVICE',
  ADD_BOOKMARK: 'ADD_BOOKMARK',
  REMOVE_BOOKMARK: 'REMOVE_BOOKMARK',
};

export const ALLOW_EMBEDDED_URLS = [
  'https://www.youtube.com',
  'https://youtu.be',
  'https://c8health.com',
];
