import React, { useCallback, useState } from 'react';
import styles from './move-popup.module.scss';
import { RenderBinderSelector } from 'src/components/knowledge-content/knowledge-content-editor/components/hub/render-binder-selector';
import { Checkbox } from 'antd';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';
import { STORE_ENTITY_TYPES } from 'shared/constants';

const MovePopupComponent = React.memo(function MovePopupComponent({
  item = {},
  categoryId,
  disabledIds = [],
  doMove = () => {},
  doCreateLink = () => {},
  onFinish = () => {},
  enableCreateLink = false,
  isOpen = false,
  generateBreadcrumbs,
  getCategoriesById,
  getCategoryById,
  getRoot,
  isCategoryMove = false,
  isSection = () => {},
  entity = STORE_ENTITY_TYPES.KNOWLEDGE,
  parentId,
}) {
  const lang = useSelector(getLang('KNOWLEDGE_MOVE'));
  const [isCreateLink, setCreateLink] = useState(false);
  const [newCategoryId, setNewCategoryId] = useState(null);

  const selectBinder = useCallback(binderAndWorskpace => {
    setNewCategoryId(binderAndWorskpace?.binderId);
  }, []);

  const toggleCreateLink = useCallback(() => {
    setCreateLink(isCreate => !isCreate);
  }, []);

  const dismiss = useCallback(() => {
    setNewCategoryId(null);
    onFinish();
  }, [onFinish]);

  const handleMove = useCallback(async () => {
    if (isCreateLink) {
      await doCreateLink({
        id: item.id,
        items: [item],
        linkOwnerIds: item.ownerIds,
        categoryId: newCategoryId,
      });
    } else {
      await doMove({
        id: item.id,
        item,
        sourceId: isCategoryMove ? parentId : categoryId,
        categoryId: newCategoryId,
      });
    }

    onFinish();
  }, [
    doMove,
    doCreateLink,
    onFinish,
    item,
    isCreateLink,
    newCategoryId,
    categoryId,
    parentId,
    isCategoryMove,
  ]);

  return (
    <RenderBinderSelector
      parentId={parentId}
      title={lang.TITLE}
      subtitle={item.title}
      submitButtonText={isCreateLink ? lang.LINK : lang.MOVE}
      filterCategories={disabledIds}
      selectTarget={selectBinder}
      onSubmit={handleMove}
      isOpen={isOpen}
      onClose={dismiss}
      getRoot={getRoot}
      getCategoriesById={getCategoriesById}
      getCategoryById={getCategoryById}
      generateBreadcrumbs={generateBreadcrumbs}
      isSection={isSection}
      entity={entity}
      isCategoryMove={isCategoryMove}
      pathParts={item?.path?.split(',')}
    >
      {enableCreateLink && (
        <div className={styles.checkbox}>
          <Checkbox onChange={toggleCreateLink} />
          <label className={styles.checkboxLabel}>{lang.CHECKBOX}</label>
        </div>
      )}
    </RenderBinderSelector>
  );
});

export { MovePopupComponent };
