import { redirectToRestrictedAccessPage } from 'src/helpers/routing.helper';

/**
 * Assert permission to restricted pages
 * @description Check if user has permission to access a restricted page. If serviceMethod throws 403, redirects to restricted access page.
 * @param {{
 *  serviceMethod: () => Promise<any>,
 *  entity: string,
 *  id: string,
 *  onFinish?: () => void
 * }} params
 * @returns {Promise<void>}
 */
export async function assertPermission({ serviceMethod, entity, id, onFinish = () => {} }) {
  const res = await serviceMethod();

  if (res?.error?.response?.status === 403) {
    redirectToRestrictedAccessPage({ entity, id });
    return;
  }
  onFinish();
}
