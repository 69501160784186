import { connect } from 'react-redux';
import { MovePopupComponent } from '../../../../../components/move/move-popup.component';
import { moveKnowledgeLink } from 'shared/store/actions/knowledge.items.actions';
import {
  generateBreadcrumbs,
  getCategoriesById,
  getCategoryById,
  getItemCategories,
  getRoot,
  getItemCategory,
  isSection,
} from 'shared/store/selectors/knowledge.selector';

const mapStateToProps = ({ lang, knowledge }, { item, categoryId }) => {
  const allCategories = getItemCategories({ knowledge }, { id: item.id });

  let itemCategory = categoryId;

  if (!itemCategory) {
    // TODO this does not work from the search page, since there can be multiple links to the same item
    itemCategory = getItemCategory({ knowledge }, { id: item.id });
  }

  return {
    lang: lang.KNOWLEDGE_MOVE,
    item,
    disabledIds: allCategories.map(({ id }) => id),
    getRoot,
    getCategoriesById,
    getCategoryById,
    generateBreadcrumbs,
    isSection,
  };
};

export const KnowledgeLinkMovePopup = connect(mapStateToProps, {
  doMove: moveKnowledgeLink,
})(MovePopupComponent);
