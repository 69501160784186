import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import styles from './render-binder-selector.module.scss';
import { Button, Divider, SelectBox } from 'src/components/design-system';
import { getSelectedWorkspaceId, getWorkspaces } from 'shared/store/selectors/workspace.selector';
import { useEffect, useMemo, useState } from 'react';
import { ConfigProvider, Modal } from 'antd';
import { CATEGORY_TYPES, colorPalette, STORE_ENTITY_TYPES } from 'shared/constants';
import { SectionComponent } from 'src/components/common/section/section.component';
import { CategoryRowComponent } from 'src/components/common/categories/category-row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faHome } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

const ENTITY_TO_CATEGORY_TYPE = {
  [STORE_ENTITY_TYPES.KNOWLEDGE]: CATEGORY_TYPES.KNOWLEDGE,
  [STORE_ENTITY_TYPES.ADMISSION]: CATEGORY_TYPES.CHECKS,
  [STORE_ENTITY_TYPES.LAB]: CATEGORY_TYPES.VALUES,
};

export function RenderBinderSelector({
  pathParts,
  parentId,
  title,
  subtitle,
  submitButtonText,
  selectTarget,
  onSubmit,
  isOpen,
  onClose,
  filterCategories,
  children = null,
  entity = STORE_ENTITY_TYPES.KNOWLEDGE,
  generateBreadcrumbs = () => {},
  getCategoriesById = () => {},
  getCategoryById = () => {},
  getRoot = () => {},
  isCategoryMove = false,
  isSection = () => {},
}) {
  const [binderId, setBinderId] = useState(null);
  const lang = useSelector(getLang('BINDER_SELECTOR'));
  const [loading, setLoading] = useState(false);
  const workspaces = useSelector(getWorkspaces);
  const initialWorkspaceId = useSelector(getSelectedWorkspaceId);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(initialWorkspaceId);
  const onSelectWorkspace = workspaceId => {
    setSelectedWorkspaceId(workspaceId);
  };

  const isSectionMove = isCategoryMove && isSection(pathParts);
  const isBinderOrDirectoryMove = isCategoryMove && !isSection(pathParts);

  const workspacesOptions = useMemo(() => {
    return workspaces.map(({ id, name }) => ({ id, value: name }));
  }, [workspaces]);

  const rootCategory = useSelector(state => getRoot(state[entity], selectedWorkspaceId));
  const [categoryId, setCategoryId] = useState(rootCategory.id);

  useEffect(() => {
    setCategoryId(rootCategory.id);
    setBinderId(null);
  }, [rootCategory, isOpen]);

  const category = useSelector(state => getCategoryById(state[entity], { id: categoryId }));
  const sections = useSelector(state =>
    getCategoriesById(state[entity], category.id, selectedWorkspaceId),
  );
  const subcategories = useSelector(state =>
    sections?.reduce(
      (acc, section) => ({
        ...acc,
        [section.id]: section.subcategories?.map(id => getCategoryById(state[entity], { id })),
      }),
      {},
    ),
  );

  const onSelectCategory = selectedId => {
    setCategoryId(selectedId);
  };

  const onSelectTarget = selectedId => {
    setBinderId(selectedId);
    selectTarget({ binderId: selectedId, targetWorkspaceId: selectedWorkspaceId });
  };

  const breadcrumbs = useSelector(state =>
    generateBreadcrumbs(state[entity], {
      pathParts: category.path.split(','),
      initialWorkspaceId: selectedWorkspaceId,
    }),
  );

  const renderRootBreadcrumb = () => (
    <h3 key={rootCategory.id} onClick={() => setCategoryId(rootCategory.id)}>
      <FontAwesomeIcon icon={faHome} />
    </h3>
  );

  const showBreadcrumbs = categoryId !== rootCategory.id;

  const renderBreadcrumbs = () => (
    <div className={styles.breadcrumbs}>
      {breadcrumbs.length ? (
        <>
          {renderRootBreadcrumb()}
          {breadcrumbs
            .filter(item => !isSection(item.path.split(',')))
            .map(item => (
              <div className={styles.afterHomeBreadcrumbs}>
                <FontAwesomeIcon icon={faChevronRight} className={styles.separator} />
                <h3 key={item.id} onClick={() => setCategoryId(item.id)}>
                  {item.title}
                </h3>
              </div>
            ))}
        </>
      ) : null}
    </div>
  );

  const renderSelectTargetButton = (id, isMoveToRoot = false) => (
    <Button
      onClick={e => {
        console.log('click');
        e.stopPropagation();
        onSelectTarget(id);
      }}
      type={binderId === id ? 'primary' : 'default'}
      disabled={parentId === id}
    >
      {isMoveToRoot ? lang.SELECT_ROOT : binderId === id ? lang.SELECTED : lang.SELECT}
    </Button>
  );

  const renderCategoriesList = () => (
    <div className={styles.categoriesContainer}>
      {sections?.map(section => (
        <SectionComponent
          title={section.title}
          isOpen={false}
          customActions={isBinderOrDirectoryMove ? renderSelectTargetButton(section.id) : undefined}
        >
          {subcategories[section.id]?.map(category => {
            const isBinder = category?.type !== CATEGORY_TYPES.CATEGORY;
            const onClick = isBinder ? onSelectTarget : onSelectCategory;
            const isDisabled =
              (isCategoryMove && isBinder) || filterCategories?.includes(category.id);

            return (
              <div
                key={category.id}
                id={category.id}
                className={classNames([styles.row, isDisabled && styles.disabled])}
                onClick={isDisabled ? undefined : () => onClick(category.id)}
              >
                <CategoryRowComponent
                  {...category}
                  categoryType={ENTITY_TO_CATEGORY_TYPE[entity]}
                  wrapperClass={binderId === category.id ? styles.selected : styles.categoryRow}
                  actions={
                    isSectionMove && !isBinder ? renderSelectTargetButton(category.id) : null
                  }
                />
              </div>
            );
          })}
        </SectionComponent>
      ))}
    </div>
  );

  const renderFooter = () => (
    <>
      <Divider />
      <div className={styles.footer}>
        <Button type="default" onClick={onClose} disabled={loading}>
          {lang.CANCEL}
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            setLoading(true);
            await onSubmit();
            setLoading(false);
            onClose();
          }}
          loading={loading}
          disabled={!binderId}
        >
          {submitButtonText}
        </Button>
      </div>
    </>
  );
  return (
    <Modal
      className="new-design-system"
      open={isOpen}
      title={<h2 className={styles.title}>{title}</h2>}
      onCancel={onClose}
      footer={renderFooter()}
      destroyOnClose
      width={800}
      centered
    >
      <div className={styles.container}>
        <p>{subtitle}</p>
        <Divider className={styles.divider} />
        <div className={styles.workspace}>
          <p>{lang.WORKSPACE}</p>
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  colorText: colorPalette.primaryMain6,
                },
              },
            }}
          >
            <SelectBox
              placeholder={lang.WORKSPACE}
              options={workspacesOptions}
              value={selectedWorkspaceId}
              onChange={(name, value) => onSelectWorkspace(value)}
              bordered={false}
              size="small"
              showSearch={false}
            />
          </ConfigProvider>
        </div>
        <Divider className={styles.divider} />

        {showBreadcrumbs && (
          <div className={styles.titleContainer}>
            {renderBreadcrumbs()}
            <h2 className={styles.title}>{category.title}</h2>
          </div>
        )}
        <div className={styles.moveToRoot}>
          {isSectionMove && renderSelectTargetButton(rootCategory.id, true)}
        </div>
        {renderCategoriesList()}
        {children}
      </div>
    </Modal>
  );
}
