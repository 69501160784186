import React, { forwardRef, useImperativeHandle, useState, useEffect, ReactNode } from 'react';
import styles from './FileUpload.module.scss';
import { FileRejection, useDropzone } from 'react-dropzone';
import { DeleteIcon, MenuDocumentIcon, UploadIcon } from '../../../images';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { ALLOWED_MIME_TYPES } from 'shared/constants/assets.constants';
import { Button } from '../../design-system/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

export interface FileWithPreview extends File {
  preview: string;
}

const SUPPORTED_FORMATS = Object.values(ALLOWED_MIME_TYPES).reduce<{ [key: string]: string[] }>(
  (acc, curr) => {
    acc[curr] = [];
    return acc;
  },
  {},
);

export const FileUpload = forwardRef(
  (
    {
      name,
      error: errorReceived = null,
      wrapperClass = null,
      onChange = () => {},
      accept = SUPPORTED_FORMATS,
      maxFiles = 1,
      preview = false,
      value = null,
      allowClear = false,
      renderIcon,
      handleBulkUpload = undefined,
      disableDragReject = false,
      deleteButtonType = 'icon',
      previewFileName = false,
    }: {
      name?: string;
      error?: string | null;
      wrapperClass?: string | null | undefined;
      onChange: (name?: string, file?: FileWithPreview | '') => void;
      accept?: { [key: string]: string[] };
      maxFiles?: number;
      preview?: boolean;
      value?: string | null | undefined;
      allowClear?: boolean;
      renderIcon?: () => ReactNode;
      handleBulkUpload?: (files: File[], fileRejections: FileRejection[]) => void | undefined;
      disableDragReject?: boolean;
      deleteButtonType?: 'icon' | 'button';
      previewFileName?: boolean;
    },
    ref,
  ) => {
    const lang = useSelector(getLang('FILE_UPLOADER'));
    const [files, setFiles] = useState<FileWithPreview[]>([]);
    const [error, setError] = useState<string | null>(errorReceived);

    useImperativeHandle(ref, () => ({
      clearFiles: () => {
        setFiles([]);
        onChange(name, ''); // Clear value externally
      },
      getCurrentFiles: () => files, // Expose files for external access
    }));

    const onChangeLocal = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const filesWithPreview = acceptedFiles.map(file =>
        Object.assign(file, {
          preview:
            file.type.indexOf('image/') !== -1 ? URL.createObjectURL(file) : MenuDocumentIcon,
        }),
      );

      setFiles(filesWithPreview);

      if (acceptedFiles.length > 1 || fileRejections.length > 1) {
        handleBulkUpload?.(acceptedFiles, fileRejections);
        return;
      }

      onChange(name, filesWithPreview[0]);
    };

    const clear = (e: any) => {
      e.stopPropagation();
      setFiles([]);
      onChange(name, ''); // clear value
    };

    const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
      accept,
      maxFiles,
      onDrop: (acceptedFiles, fileRejections) => {
        if (!acceptedFiles.length && !disableDragReject) {
          return;
        }

        if (acceptedFiles.length + fileRejections.length > maxFiles) {
          setError(lang.TOO_MANY_FILES);
          return;
        }
        setError(null);
        onChangeLocal(acceptedFiles, fileRejections);
      },
    });

    useEffect(
      () => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
      },
      [files],
    );

    const isRejected = (isDragReject && !disableDragReject) || error;

    const borderColor = isRejected ? '#d6473e' : '#3ba5f4';
    const backgroundColor = isRejected ? 'rgba(214, 71, 62, .2)' : undefined;
    const color = isRejected ? '#d6473e' : undefined;

    const thumbs = files.map(file => (
      <div className={styles.thumbWrapper} key={file.name}>
        <div className={styles.thumb}>
          <img src={file.preview} alt={file.name} />
        </div>
        <div className={styles.labelWrapper}>
          <p className={styles.fileLabel}>{file.name}</p>
          <p className={styles.readyLabel}>{lang.READY_FOR_UPLOAD}</p>
        </div>
      </div>
    ));

    const singlePreview = ({ src, alt }: { src: string | undefined; alt: string | undefined }) => (
      <img className={styles.preview} src={src} alt={alt} />
    );

    const renderInputContent = () => {
      if (isDragAccept) {
        return lang.DROP_FILES;
      }

      if (isRejected) {
        return <p className={styles.error}>{error || lang.FORMAT_NOT_ALLOWED}</p>;
      }

      if (files.length > 0) {
        if (preview) {
          return singlePreview({ src: files[0]?.preview, alt: files[0]?.name });
        }

        return <>{thumbs}</>;
      }

      if (value && preview) {
        return singlePreview({ src: value, alt: name });
      }

      if (value && previewFileName) {
        const defaultFileName = value.split('/').pop() || '';
        return <p className={styles.fileName}>{defaultFileName}</p>;
      }

      if (preview) {
        return (
          <div className={styles.uploadIcon}>
            <img src={UploadIcon} alt="upload" />
          </div>
        );
      }

      return renderIcon ? renderIcon() : lang.PLACEHOLDER;
    };

    return (
      <div className={styles.root}>
        <div
          style={{ borderColor, backgroundColor, color }}
          {...getRootProps({ className: wrapperClass ?? styles.wrapper })}
        >
          <input {...getInputProps()} />
          {renderInputContent()}

          {allowClear && (files.length > 0 || value) && (
            <>
              {deleteButtonType === 'icon' ? (
                <DeleteIcon className={styles.deleteIcon} onClick={clear} />
              ) : (
                <Button
                  className={styles.deleteButton}
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  size="middle"
                  onClick={clear}
                  danger
                >
                  {lang.REMOVE}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    );
  },
);
