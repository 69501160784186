import userService from '../../services/user.service';
import {
  APPLICATION_REFRESH_DATE,
  APP_IS_READY,
  AUTH_LOGIN,
  AUTH_UPDATE_TOKEN,
  KNOWLEDGE_CREATE_ITEM,
  KNOWLEDGE_CREATE_LINK,
  KNOWLEDGE_DASHBOARD_UPDATE_CONTENT,
  KNOWLEDGE_DELETE_CATEGORY,
  KNOWLEDGE_DELETE_ITEM,
  KNOWLEDGE_DELETE_LINK,
  KNOWLEDGE_MOVE_LINK,
  KNOWLEDGE_UPDATE_CATEGORY,
  KNOWLEDGE_UPDATE_CATEGORY_LOCATION,
  KNOWLEDGE_UPDATE_CONTENT,
  KNOWLEDGE_UPDATE_ITEM,
  KNOWLEDGE_UPDATE_ITEM_LOCATION,
  NETWORK_CONNECTION_CHANGE_ONLINE,
  OWNERS_TRANSFER_OWNERSHIP,
} from '../actions';
import { getKnowledgeContent, getKnowledgeDashboardContent } from '../actions/knowledge.actions';
import { updateStateToStorage } from '../actions/storage.actions';

export const knowledgeDataFetchedMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    if (
      action.type === KNOWLEDGE_UPDATE_CONTENT ||
      action.type === KNOWLEDGE_DASHBOARD_UPDATE_CONTENT
    ) {
      dispatch(updateStateToStorage());
    }
  };

export const knowledgeFetchDataMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === AUTH_LOGIN ||
      action.type === APP_IS_READY ||
      action.type === AUTH_UPDATE_TOKEN ||
      action.type === APPLICATION_REFRESH_DATE ||
      action.type === OWNERS_TRANSFER_OWNERSHIP
    ) {
      if (!userService.isAuthenticated) return false;

      dispatch(getKnowledgeContent());
      dispatch(getKnowledgeDashboardContent());
    }
  };

export const knowledgeDataReconnectToNetworkMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    if (action.type === NETWORK_CONNECTION_CHANGE_ONLINE) {
      dispatch(getKnowledgeContent());
      dispatch(getKnowledgeDashboardContent());
    }
  };

export const knowledgeOnCategoryChangeOwnerIdMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === KNOWLEDGE_UPDATE_CATEGORY) {
      if (!action.payload.ownerIds) return false;

      dispatch(getKnowledgeContent());
    }
  };

export const knowledgeOnItemUpdateFetchMetadata =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === KNOWLEDGE_CREATE_ITEM ||
      action.type === KNOWLEDGE_DELETE_ITEM ||
      action.type === KNOWLEDGE_UPDATE_ITEM ||
      action.type === KNOWLEDGE_UPDATE_ITEM_LOCATION ||
      action.type === KNOWLEDGE_CREATE_LINK ||
      action.type === KNOWLEDGE_DELETE_LINK ||
      action.type === KNOWLEDGE_MOVE_LINK ||
      action.type === KNOWLEDGE_DELETE_CATEGORY ||
      action.type === KNOWLEDGE_UPDATE_CATEGORY_LOCATION
    ) {
      dispatch(getKnowledgeContent());
      dispatch(getKnowledgeDashboardContent());
    }
  };

export const knowledgeMiddleware = [
  knowledgeDataFetchedMiddleware,
  knowledgeDataReconnectToNetworkMiddleware,
  knowledgeFetchDataMiddleware,
  knowledgeOnCategoryChangeOwnerIdMiddleware,
  knowledgeOnItemUpdateFetchMetadata,
];
