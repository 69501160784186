import React, { forwardRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import styles from './image-upload-box.module.scss';
import { SUPPORTED_IMG_FORMATS } from 'shared/constants/assets.constants';
import classNames from 'classnames';
import { FileUpload } from 'src/components/core';
import { useDispatch } from 'react-redux';
import { uploadDocument } from 'shared/store/actions/knowledge.items.actions';
import { Image } from 'antd';
import { colorPalette } from 'shared/constants';

export const ImageUploadBox = forwardRef(
  ({ previewLabel, value, label, error, name, onChange, allowClear = false }, ref) => {
    const [uploading, setUploading] = useState(false);
    const dispatch = useDispatch();

    const handleUpload = async (name, file) => {
      if (!file) {
        // on clear
        if (allowClear) {
          onChange(name, '');
        } else {
          onChange(name, value || '');
        }
        return;
      }

      setUploading(true);
      const { url } = await dispatch(uploadDocument(file));

      if (!url) {
        return;
      }

      onChange(name, url);
      setUploading(false);
    };

    return (
      <div className={styles.root}>
        {uploading && (
          <FontAwesomeIcon className={styles.loader} icon={faSpinner} spin={uploading} size="2xl" />
        )}

        {value && !uploading && (
          <div className={styles.previewWrapper}>
            <label className={styles.label}>{previewLabel}</label>
            <Image
              width="3.75rem"
              height="3.75rem"
              className={styles.previewImage}
              src={value}
              alt="preview"
              preview={{
                mask: <FontAwesomeIcon color={colorPalette.neutral1} icon={faEye} size="lg" />,
              }}
            />
          </div>
        )}

        <div className={styles.fileUploadWrapper}>
          <label className={styles.label}>{label}</label>
          <FileUpload
            ref={ref}
            key={label}
            name={name}
            error={error}
            value={value}
            accept={SUPPORTED_IMG_FORMATS}
            wrapperClass={classNames(styles.uploadWrapper, {
              [styles.errorWrapper]: error !== undefined,
            })}
            inputStyle="editor"
            onChange={handleUpload}
            allowClear
            previewFileName
          />
          {error && <span className={styles.error}>{error}</span>}
        </div>
      </div>
    );
  },
);
