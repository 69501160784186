import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getKnowledgeItemById, isKnowledgeReady } from 'shared/store/selectors/knowledge.selector';
import { KnowledgeContentComponent } from '../../../components/knowledge-content';
import { REQUEST_ACCESS_ENTITIES, ROUTES } from 'shared/constants';
import { NotFound } from '../../../components/common';
import { isEmpty } from 'lodash';
import { Loader } from '../../../components/core';
import { ContentPreviewProvider } from './hooks/use-content-preview.hooks';
import knowledgeServiceApi from 'shared/api/knowledge.service.api';
import { assertPermission } from 'src/components/common/access-restricted/assert-permission';

const KnowledgeContentPage = React.memo(() => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const item = useSelector(state => getKnowledgeItemById(state, id));
  const isReady = useSelector(state => isKnowledgeReady(state));
  const [loading, setLoading] = React.useState(true);

  const onClose = useCallback(() => {
    if (!location.key) {
      history.push(ROUTES.HOME);
      return;
    }

    history.goBack();
  }, [history, location]);

  useEffect(() => {
    if (isReady) {
      assertPermission({
        serviceMethod: async () => await knowledgeServiceApi.getKnowledgeItemById({ id }),
        entity: REQUEST_ACCESS_ENTITIES.KNOWLEDGE_ITEM,
        id,
        onFinish: () => setLoading(false),
      });
    }
  }, [isReady, id]);

  if (loading) return <Loader />;

  if (!item || isEmpty(item)) return <NotFound />;

  return (
    <ContentPreviewProvider>
      <KnowledgeContentComponent {...item} close={onClose} />;
    </ContentPreviewProvider>
  );
});

export { KnowledgeContentPage };
