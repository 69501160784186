import styles from './forms.module.scss';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';
import { Tooltip } from 'src/components/core';

export function RequiredIndication({ label }) {
  const lang = useSelector(getLang('GENERAL'));

  return (
    <>
      {label}
      <Tooltip title={lang.MANDATORY_FIELD}>
        <span className={styles.required}> *</span>
      </Tooltip>
    </>
  );
}
