import { Col, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { getContactItemById } from 'shared/store/selectors/contact.selector';
import { formatFullName, formatInitials } from 'shared/utils';
import { ProfileLayoutComponent } from 'src/components/profiles/profile-layout.component';
import styles from './contact-preview.module.scss';
import { Button } from 'src/components/design-system';
import { getLang } from 'shared/store/selectors/lang.selector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPager, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { useProfileLang } from 'shared/hooks/profile.hooks';
import { CONTACT_TYPES, ROLES_DOCTOR, ROUTES } from 'shared/constants';
import _ from 'lodash';
import { isAccountAdmin } from 'shared/store/selectors/auth.selector';
import { Loader } from 'src/components/core';
import classnames from 'classnames';
import { Avatar } from 'src/components/avatar/avatar.component';
import { ProfessionalProfileManager } from 'src/components/profiles/professional-profile-manager';
import { deleteContact } from 'shared/store/actions/contacts.actions';
import { deleteUser } from 'shared/store/actions/users-management.actions';

const ContactPreviewPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const contact = useSelector(({ contacts, usersManagement }) =>
    getContactItemById({ contacts, usersManagement }, id),
  );

  const dispatch = useDispatch();
  const lang = useSelector(getLang('USER_PROFILE'));
  const contactsLang = useSelector(getLang('CONTACT_SCREEN'));
  const { getValueFromLang, getMultipleValuesFromLang, getLocationsValues } = useProfileLang();

  const canEditDetails = useSelector(isAccountAdmin);
  const isUser = contact?.isUser;
  const canEditResourcesAndPreferences = canEditDetails && isUser;

  const onEdit = () => {
    history.push(ROUTES.CONTACTS_EDIT.replace(':id', id));
  };

  const goToMyOrgPage = () => {
    history.push(ROUTES.CONTACTS);
  };

  const onDelete = async () => {
    await dispatch(isUser ? deleteUser({ id }) : deleteContact({ id }));
    goToMyOrgPage();
  };

  const mainSection = [
    {
      name: 'email',
      label: lang.EMAIL,
      value: contact?.email,
      icon: faEnvelope,
      iconAction: () => window.open(`mailto:${contact?.email}`),
      render: getValueFromLang,
    },
    {
      name: 'phone',
      label: lang.PHONE,
      value: contact?.phone,
      icon: faPhone,
      iconAction: () => window.open(`tel:${contact?.phone}`),
      render: getValueFromLang,
    },
    {
      name: 'pager',
      label: lang.PAGER,
      value: contact?.pager,
      icon: faPager,
      iconAction: () => window.open(`tel:${contact?.pager}`),
      render: getValueFromLang,
    },
    {
      name: 'cisco',
      label: lang.CISCO,
      value: contact?.cisco,
      icon: faPhone,
      iconAction: () => window.open(`tel:${contact?.cisco}`),
      render: getValueFromLang,
    },
    {
      name: 'profession',
      label: lang.PROFESSION,
      value: contact?.profession,
      render: getValueFromLang,
      hide: !isUser,
    },
    {
      name: 'department',
      label: lang.DEPARTMENT,
      value: contact?.professional?.department,
      render: getValueFromLang,
    },
    {
      name: 'role',
      label: lang.ROLE,
      value: contact?.professional?.role,
      render: getValueFromLang,
      hide: !isUser,
    },
    {
      name: 'levelOfTraining',
      label: lang.LEVEL_OF_TRAINING,
      value: contact?.professional?.levelOfTraining,
      render: getValueFromLang,
      hide: !isUser,
    },
    {
      name: 'rotation',
      label: lang.ROTATION,
      value: contact?.professional?.rotation,
      render: getValueFromLang,
      hide: contact?.professional?.role !== ROLES_DOCTOR.RESIDENT || !isUser,
    },
    {
      name: 'division',
      label: lang.DIVISION,
      value: contact?.professional?.division,
      render: getMultipleValuesFromLang,
      hide: contact?.professional?.role === ROLES_DOCTOR.RESIDENT || !isUser,
    },
    {
      name: 'location',
      label: lang.LOCATION,
      value: contact?.professional?.location,
      render: getLocationsValues,
    },
    {
      name: 'specialty',
      label: lang.SPECIALTY,
      value: contact?.professional?.specialty,
      render: getMultipleValuesFromLang,
      hide: !isUser,
    },
  ];

  const formFieldsOrder = isUser
    ? [
        'email',
        'profession',
        'department',
        'phone',
        'role',
        'rotation',
        'division',
        'cisco',
        'levelOfTraining',
        'location',
        'pager',
        'specialty',
      ]
    : ['email', 'phone', 'pager', 'cisco', 'department', 'location'];

  const renderTopSection = () => (
    <Col span={24} className={styles.topSection}>
      <div className={styles.container}>
        <div className={styles.avatarWrapper}>
          <Avatar alt={formatInitials(contact)} size="large" />
        </div>
        <div className={styles.header}>
          <p className={styles.backButton} onClick={goToMyOrgPage}>
            {contactsLang.TITLE}
          </p>
          <h1 className={styles.title}>{formatFullName(contact)}</h1>
          <p className={styles.subtitle}>{contact?.professional?.title}</p>
        </div>
      </div>
      {canEditDetails && (
        <div className={styles.buttons}>
          <Popconfirm
            title={contactsLang.DELETE_CONFIRM.replace(
              '{entity}',
              isUser ? CONTACT_TYPES.USER : CONTACT_TYPES.CONTACT,
            )}
            cancelText={lang.CANCEL}
            okText={contactsLang.DELETE}
            okType="danger"
            onConfirm={onDelete}
            okButtonProps={{ type: 'primary' }}
            cancelButtonProps={{ type: 'default' }}
            placement="bottomLeft"
          >
            <Button type="primary" danger>
              {contactsLang.DELETE}
            </Button>
          </Popconfirm>
          <Button type="primary" onClick={onEdit}>
            {lang.EDIT}
          </Button>
        </div>
      )}
    </Col>
  );

  const renderBottomSection = () => {
    if (!contact?.isUser) return null;

    return <ProfessionalProfileManager user={contact} canEdit={canEditResourcesAndPreferences} />;
  };

  const renderMainSection = () =>
    formFieldsOrder
      .map(field => mainSection.find(f => f.name === field))
      .map(({ label, value, icon, render, iconAction = () => {}, hide }, index) => {
        if (hide) return null;

        let valueToPresent = render?.(value);
        if (_.isEmpty(valueToPresent)) {
          valueToPresent = '-';
        }

        const empty = valueToPresent === '-';
        const showIcon = icon && valueToPresent !== '-';

        return (
          <Col
            key={index}
            span={8}
            className={classnames([styles.fieldWrapper, empty && styles.empty])}
          >
            <p className={styles.label}>{label}</p>
            <div className={styles.row}>
              <p className={styles.value}>{valueToPresent}</p>
              {showIcon && (
                <FontAwesomeIcon
                  className={styles.icon}
                  size="xl"
                  icon={icon}
                  onClick={iconAction}
                />
              )}
            </div>
          </Col>
        );
      });

  if (!contact) {
    return <Loader />;
  }

  return (
    <ProfileLayoutComponent
      renderTopSection={renderTopSection}
      renderMainSection={renderMainSection}
      renderBottomSection={renderBottomSection}
    />
  );
};

export { ContactPreviewPage };
