export const ROUTES = {
  ONBOARDING: 'Onboarding',
  WELCOME: '/signin',
  OTP_VERIFICATION: '/verification',
  NOT_ALLOWED: '/not-allowed',
  OAUTH_CALLBACK: '/auth/callback/:provider',
  SETTINGS: '/settings',
  SETTINGS_MAGIC_LINK: '/settings/magic-link',
  SETTINGS_WORKSPACES: '/settings/workspaces',
  RESTRICTED: '/restricted',

  ROOT: '/',
  PDF_VIEWER: 'Pdf Viewer',
  NOTIFICATION_MODAL: 'Notification Modal',
  HOME: '/',
  CONTENT_ITEM: '/knowledge/:id',
  ITEMS: 'Knowledge items',

  CHECKLISTS: 'Checklists',
  LABS: '/compendium',
  LABS_CATEGORIES: '/compendium/:id',

  ADMISSION: '/admission',
  ADMISSION_CATEGORIES: '/admission/:id',

  FAVORITES: '/favorites',
  SEARCH: '/search',

  USER_PROFILE_POPUP: '/profile-popup',
  USER_PROFILE: '/profile',
  SWITCH_ORGANIZATION: '/organizations',
  OWNERS_MANAGEMENT: '/owners',
  USERS_MANAGEMENT: '/users',
  COMMUNICATION_CENTER: '/communication',
  KNOWLEDGE: '/knowledge',
  KNOWLEDGE_ITEMS: '/knowledge/items/:id',

  ROTATIONS: '/rotations',
  CONTACTS: '/contacts',
  CONTACTS_PREVIEW: '/contacts/:id',
  CONTACTS_EDIT: '/contacts/:id/edit',
  SUGGEST_CONTENT: '/suggest-content',
  KNOWLEDGE_HUB: '/hub',
  KNOWLEDGE_HUB_ITEMS: '/hub/items/:id',
  KNOWLEDGE_HUB_SEARCH: '/hub/search',
};

export const INTERNAL_ROUTING = {
  QUERY_PARAMS: {
    KNOWLEDGE: 'category',
    CHECKLIST: 'checklist',
    COMPENDIUM: 'values',
    CONTACTS: 'id',
    USERS: 'id',
    ROTATIONS: 'id',
    HUB: 'hub-category',
    RESTRICTED_ID: 'id',
    RESTRICTED_ENTITY: 'entity',
  },
};
